
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AgeClass } from '../models/ageClass';
import { ConvService } from './conv.service';
import { CrudService } from './crud.service';
import { TsHttpService } from './ts-http.service';


@Injectable({
  providedIn: 'root'
})
export class AgeClassService extends CrudService<AgeClass> {

  /*
   * Prefix for the API-Call
   */
  static prefix = 'ageClasses';
  dbTable = 'age_class';
  allCache: AgeClass[] = [];
  frontpageCache: AgeClass[] = [];

  constructor(public httpSrv: TsHttpService, public convSrv: ConvService) {
    super(httpSrv, AgeClassService.prefix, AgeClass, convSrv);
  }

  public findAll(): Observable<AgeClass[]> {
    if (!this.allCache || this.allCache.length === 0) {
      return this.httpSrv.get<AgeClass[]>(`${AgeClassService.prefix}`).pipe(
        map(elems => this.convServ.convert2TypedArray<AgeClass>(elems, AgeClass)),
        tap(ageClasses => this.allCache = ageClasses));
    }
    return of(this.allCache);
  }

  public frontPage() {
    if (!this.frontpageCache || this.frontpageCache.length === 0) {
      return this.httpSrv
        .get<AgeClass[]>(`${AgeClassService.prefix}/frontpage`).pipe(
          map(schools => this.convServ.convert2TypedArray<AgeClass>(schools, AgeClass)),
          tap(ageClasses => this.frontpageCache = ageClasses));
    }
    return of(this.frontpageCache);
  }

  public byNameUrl(nameUrl: string) {
    return this.httpSrv
      .get<AgeClass>(`${AgeClassService.prefix}/nameUrl/${nameUrl}`).pipe(
        map(result => Object.assign(new AgeClass, result))
      );
  }

}
