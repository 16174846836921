<div class="my-4 mt-8 shadow-none rounded-larger p-4">
  <div>
    <h2 class="text-3xl font-bold">Kontakt</h2>
    <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 justify-between mt-4">
      <div class="flex space-x-4 items-center">
        <a [href]="school.maps_link" target="_blank">
          <button class="flex items-center justify-center">
            <mat-icon color="primary">navigation</mat-icon>
          </button>
        </a>
        <div>
          {{ school.company_name }}
          <br />
          {{ school.street }} <br />
          {{ school.plz }} {{ school.city }}
        </div>
      </div>

      <div class="flex space-x-4 items-center">
        <a [href]="'tel:' + school.telephone">
          <button class="flex items-center justify-center">
            <mat-icon color="primary">phone</mat-icon>
          </button>
        </a>
        <div class="flex-col space-y-2">
          <div class="flex flex-col">
            <span class="text-xs text-gray-600">Telefon:</span>
            <a [href]="'tel:' + school.telephone"> {{ school.telephone }}</a>
          </div>
          <div class="flex flex-col" *ngIf="school.telefax">
            <span class="text-xs text-gray-600">Telefax:</span>
            <span>{{ school.telefax }}</span>
          </div>
        </div>
      </div>
      <div class="flex space-x-4 items-center">
        <a [href]="'mailto:' + school.e_mail">
          <button class="flex items-center justify-center">
            <mat-icon color="primary">email</mat-icon>
          </button>
        </a>
        <div>
          <div class="flex flex-col">
            <span class="text-gray-600 text-xs">E-Mail:</span>
            <a [href]="'mailto:' + school.e_mail">{{ school.e_mail }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--<mgl-map [style]="'mapbox://styles/mapbox/streets-v9'" [zoom]="[13]" [center]="[0, 0]" (load)="updateMap($event)">
    <mgl-marker [lngLat]="[school.lng, school.lat]"></mgl-marker>
  </mgl-map>-->
</div>
