<button
  class="hover:bg-orangeHover duration-100 flex rounded-full px-3 py-1.5 text-sm items-center space-x-1"
  [matMenuTriggerFor]="courseToolbarMenu"
>
  <mat-icon>&#xe405;</mat-icon>
  <div>Kurse</div>
</button>
<mat-menu #courseToolbarMenu="matMenu">
  <button
    *ngFor="let ageClass of ageClasses"
    mat-menu-item
    [routerLink]="['kurse', ageClass.nameUrl]"
  >
    <mat-icon [innerHtml]="ageClass.icon"></mat-icon>
    {{ ageClass.name }}
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item [routerLink]="['konditionen']">
    <mat-icon>&#xe926;</mat-icon>
    Konditionen
  </button>

  <button mat-menu-item [routerLink]="['kursfreie-zeiten']">
    <mat-icon>&#xe033;</mat-icon>
    kursfreie Zeiten
  </button>

  <a [href]="assetsRoot + school.flyer" target="_blank">
    <button mat-menu-item>
      <mat-icon>&#xe415;</mat-icon>
      Download Flyer
    </button>
  </a>
</mat-menu>
