import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { School } from '../models/school';
import { SchoolService } from '../services/school.service';
import { PictureComponent } from '../shared/picture/picture.component';

@Component({
  selector: 'ts-tanzspass-com',
  standalone: true,
  imports: [
    PictureComponent,
  ],
  templateUrl: './tanzspass-com.component.html',
  styleUrl: './tanzspass-com.component.css'
})
export class TanzspassComComponent implements OnInit {

  schools: School[] = [];

  constructor(
    private schoolSrv: SchoolService,
  ) { }

  capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  async ngOnInit(): Promise<void> {
    this.schools = await firstValueFrom(this.schoolSrv.findAll());
  }
}
