import { ConvService } from '../services/conv.service';
import { DbObj } from './dbObj';


export class Concept implements DbObj {

    public id: number;
    public text: string;

    get prettyName(): string {
        return ConvService.truncate(this.text, 30);
    }

    get subtitle(): string {
        return '';
    }

    constructor() { }
}
