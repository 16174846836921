import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { CourseLevel } from './courseLevel';
import { CourseStartDate } from './courseStartDate';
import { DbObj } from './dbObj';
import { School } from './school';

dayjs.extend(utc);
dayjs.extend(timezone)

export class Course implements DbObj {

  public id: number;
  public weekday: string;
  public description: string;
  public subtext: string;
  public price3: number;
  public price6: number;
  public price12: number;
  public priceComplete: number;
  public time: Date;
  public time_end: Date;
  public course_start_dates: CourseStartDate[];
  private school: School;
  private fk_room: number | null;
  private fk_teammember: number | null;
  public courseLevel: CourseLevel;
  public noStartDatesText: String;
  public isShownInHolidayCourses: boolean;
  public isFeatured: boolean;
  public isFull: boolean;

  constructor() { }

  get prettyName() {
    return `[${this.courseLevel
      && this.courseLevel.ageClass
      && this.courseLevel.ageClass.name.slice(0, 3).toUpperCase()}] ${this.description}`;
  }

  get subtitle() {
    return `${this.weekday}, ${dayjs(this.time).tz('Europe/Berlin').format('HH:mm')}`;
  }

  public getTimeText() {
    const time = new Date(this.time);
    const time_end = new Date(this.time_end);
    return `${dayjs(this.time).tz('Europe/Berlin').format('HH:mm')} - ${dayjs(this.time_end).tz('Europe/Berlin').format('HH:mm')} Uhr`;
  }

  public getShortWeekday() {
    return this.weekday.substr(0, 2) + '.';
  }

  public isDisabled() {
    if (this.isFull) {
      return true;
    }
    if (this.course_start_dates && this.course_start_dates.length === 1) {
      // const date = new Date(this.course_start_dates[0].date);

      return (this.course_start_dates[0].is_full === 1

        || (this.course_start_dates[0].is_full_for_f === 1 && this.course_start_dates[0].is_full_for_m === 1) /* oder pastDays */);
    }
    return false;
  }
}
