
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Teammember } from '../models/teammember';
import { ConvService } from './conv.service';
import { CrudService } from './crud.service';
import { TsHttpService } from './ts-http.service';


@Injectable({
  providedIn: 'root'
})
export class TeamService extends CrudService<Teammember> {

  static prefix = 'teammembers';
  dbTable = 'teammember';

  constructor(
    public httpSrv: TsHttpService,
    public convSrv: ConvService
  ) {
    super(httpSrv, TeamService.prefix, Teammember, convSrv);
  }

  public bySchoolId(schoolId: number) {
    return this.httpSrv
      .get<Teammember[]>(`schools/${schoolId}/${TeamService.prefix}`).pipe(
        map(members => this.convSrv.convert2TypedArray<Teammember>(members, Teammember)));
  }
}
