import { Injectable } from '@angular/core';
import { Concept } from '../models/concept';
import { ConvService } from './conv.service';
import { CrudService } from './crud.service';
import { TsHttpService } from './ts-http.service';

@Injectable({
  providedIn: 'root'
})
export class ConceptService extends CrudService<Concept> {

  static prefix = 'concepts';
  dbTable = 'concept';

  constructor(
    public httpSrv: TsHttpService,
    public convSrv: ConvService,
  ) {
    super(httpSrv, ConceptService.prefix, Concept, convSrv);
  }
}
