import { Component, OnInit } from '@angular/core';
import { SchoolService } from '../../services/school.service';
import { School } from '../../models/school';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { PictureComponent } from '../../shared/picture/picture.component';

@Component({
    selector: 'ts-room-teaser',
    templateUrl: './room-teaser.component.html',
    styleUrls: ['./room-teaser.component.css'],
    standalone: true,
    imports: [PictureComponent, NgIf, RouterLink]
})
export class RoomTeaserComponent implements OnInit {

  public school: School = new School();

  constructor(private schoolSrv: SchoolService) { }

  ngOnInit() {
    this.schoolSrv.current().subscribe(school => {
      this.school = school;
    });
  }

}
