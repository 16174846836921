import { animate, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser, NgFor, NgIf } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { TvSlide } from '../../models/tvSlide';
import { TsHttpService } from '../../services/ts-http.service';
import { TvSlideService } from '../../services/tv-slide.service';
import { PictureComponent } from '../../shared/picture/picture.component';

@Component({
    selector: 'ts-infosystem-gallery',
    templateUrl: './infosystem-gallery.component.html',
    styleUrls: ['./infosystem-gallery.component.css'],
    animations: [
        trigger("inOutAnimation", [
            transition(":enter", [
                style({ opacity: 0 }),
                animate(".3s ease-in", style({ opacity: 1 })),
            ]),
            transition(":leave", [
                style({ opacity: 1 }),
                animate(".3s ease-out", style({ opacity: 0 })),
            ]),
        ]),
    ],
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        PictureComponent,
    ],
})
export class InfosystemGalleryComponent implements OnInit {

  @Input() schoolId: number;
  slides: TvSlide[] = [];
  count = 0;
  currentIndex = -1;

  constructor(
    private tvSlideSrv: TvSlideService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  updateSlides() {
    return new Promise<void>((resolve) => {
      this.tvSlideSrv.findBySchool(this.schoolId).subscribe((slides) => {
        this.slides = slides;
        this.count = slides.length;
        resolve();
      });
    });
  }

  getVideoPath(filename: string) {
    return `${TsHttpService.assetsRoot}${filename}`;
  }

  incIndex() {
    this.currentIndex =
      this.currentIndex < this.count - 1 ? this.currentIndex + 1 : 0;
    const currentSlide = this.slides[this.currentIndex];
    if (currentSlide.bg_img) {
      setTimeout(() => {
        this.incIndex();
      }, TvSlideService.slideDuration);
    }
  }

  async ngOnInit(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      await this.updateSlides();
      this.incIndex();

      /* setInterval(() => {
        this.updateSlides();
      }, 60000); */
    }
  }

}
