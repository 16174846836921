import {
  Component, OnInit, Input, Output,
  ViewChild, EventEmitter, OnChanges
} from '@angular/core';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { School } from '../../models/school';
import { SchoolService } from '../../services/school.service';
import { SchoolSidenavMenuComponent } from '../../school/school-sidenav-menu/school-sidenav-menu.component';
import { EventSidenavMenuComponent } from '../../event/event-sidenav-menu/event-sidenav-menu.component';
import { MatIcon } from '@angular/material/icon';
import { CourseSidenavMenuComponent } from '../../course/course-sidenav-menu/course-sidenav-menu.component';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'ts-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.css'],
    standalone: true,
    imports: [MatSidenavContainer, MatSidenav, RouterLink, CourseSidenavMenuComponent, MatIcon, EventSidenavMenuComponent, SchoolSidenavMenuComponent]
})
export class SidenavComponent implements OnInit, OnChanges {

  @Input() open: boolean;
  @Output() openedChange = new EventEmitter<boolean>();
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  public over: string;

  public school: School = new School();

  constructor(private schoolSrv: SchoolService) { }

  public openedChanged(isOpen: boolean) {
    this.openedChange.emit(isOpen);
  }

  ngOnInit() {
    this.schoolSrv.current().subscribe((school) => {
      this.school = school;
    });
  }

  ngOnChanges() {
    this.open ? this.sidenav.open() : this.sidenav.close();
  }

}
