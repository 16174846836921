import { Course } from "./course";
import { DbObj } from "./dbObj";
import { School } from "./school";

export class Room implements DbObj {
  public id: number;
  public name_short: string;
  public name: string;
  public icon_name: string;
  public imgTeaser: string;
  public img1: string;
  public img2: string;
  public img3: string;
  public img4: string;
  public fk_school: number;
  public school: School;
  public courses: Course[] = [];
  public showInOverview: boolean = true;
  public showAllCoursesInToolbar: boolean;
  public currentCourse: Course;
  public nextCourse: Course;
  public timeUntilNextCourse: string;

  constructor() { }

  get prettyName() {
    return this.name;
  }

  get subtitle() {
    return "";
  }
}
