import { NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Concept } from '../../models/concept';
import { ConceptService } from '../../services/concept.service';

@Component({
    selector: 'ts-school-concept',
    templateUrl: './school-concept.component.html',
    styleUrls: ['./school-concept.component.css'],
    standalone: true,
    imports: [NgFor],
    host: {ngSkipHydration: 'true'},
})
export class SchoolConceptComponent implements OnInit {

  conceptElems: Concept[] = [];

  constructor(private conceptSrv: ConceptService) { }

  ngOnInit() {
    this.conceptSrv.findAll().subscribe(conceptElems => {
      this.conceptElems = conceptElems;
    });
  }

}
