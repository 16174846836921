import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Course } from "../models/course";
import { CourseStartDate } from "../models/courseStartDate";
import { Person } from "../models/person";
import { Room } from "../models/room";
import { ConvService } from "./conv.service";
import { CrudService } from "./crud.service";
import { SchoolService } from "./school.service";
import { TsHttpService } from "./ts-http.service";

@Injectable({
  providedIn: "root",
})
export class CourseService extends CrudService<Course> {
  static prefix = "courses";
  static prefixRegister = "registrations";
  public dbTable = "course";

  constructor(public httpSrv: TsHttpService, public convSrv: ConvService) {
    super(httpSrv, CourseService.prefix, Course, convSrv);
  }

  public convertCourses(courses: Course[]) {
    const newCourses: Course[] = new Array();
    courses.forEach((course) => {
      const newCourse: Course = new Course();
      newCourse.course_start_dates = new Array();

      if (course?.course_start_dates) {
        course.course_start_dates.forEach((startDate) => {
          const newStartDate: CourseStartDate = new CourseStartDate();
          Object.assign(newStartDate, startDate);
          newCourse.course_start_dates.push(newStartDate);
        });
      }
      Object.assign(newCourse, course);
      newCourses.push(newCourse);
    });
    return newCourses;
  }

  public byCourseLevelId(id: number, schoolId: number): Observable<Course[]> {
    return this.httpSrv
      .get<Course[]>(
        `${CourseService.prefix}?courseLevelID=${id}&schoolID=${schoolId}`
      )
      .pipe(map(this.convertCourses));
  }

  public nextSpecialCourses(schoolId: number) {
    return this.httpSrv
      .get<Course[]>(
        `${SchoolService.prefix}/${schoolId}/courses/nextSpecialCourses`
      )
      .pipe(map(this.convertCourses));
  }

  public featuredCourses(schoolId: number) {
    return this.httpSrv
      .get<Course[]>(`${SchoolService.prefix}/${schoolId}/courses/featured`)
      .pipe(map(this.convertCourses));
  }

  public currentCourse(roomId: number): Observable<Course> {
    return this.httpSrv
      .get<Course>(`${CourseService.prefix}/current/${roomId}`)
      .pipe(map((course) => this.convertCourses([course])[0]));
  }

  public nextCourse(roomId: number): Observable<Course> {
    return this.httpSrv
      .get<Course>(`${CourseService.prefix}/next/${roomId}`)
      .pipe(map((course) => this.convertCourses([course])[0]));
  }

  public upcommingCourses(schoolId: number) {
    return this.httpSrv.get<
      {
        room: Room;
        currentCourse: Course;
        nextCourse: Course;
      }[]
    >(`${SchoolService.prefix}/${schoolId}/courses/upcomming`);
  }
  public register(
    person: Person,
    partner: Person,
    parent: Person,
    notes: string,
    courseID: number,
    startDateID: number,
    paymentMethodId: number,
    contractDuration: number,
    voucher_code: string,
    capchaToken: string
  ) {
    const payload: any = {};
    payload.person = person;
    payload.partner = partner;
    payload.parent = parent;
    payload.notes = notes;
    payload.courseID = courseID;
    payload.startDateID = startDateID;
    payload.paymentMethodId = paymentMethodId;
    payload.contractDuration = contractDuration;
    payload.voucher_code = voucher_code;
    payload.capchaToken = capchaToken;

    return this.httpSrv.post<any>(
      `${CourseService.prefixRegister}`,
      JSON.stringify(payload)
    );
  }
}
