import { Injectable } from '@angular/core';
import { TsHttpService } from './ts-http.service';

@Injectable({
  providedIn: 'root'
})
export class ConvService {

  static convAssetPath(path: string) {
    if (typeof path === 'string') {
      if (path) {
        return {
          jpg: TsHttpService.assetsRoot + path,
          webp: TsHttpService.assetsRoot + 'webp/' + path.replace('.jpg', '.webp')
        };
      }
      return {
        jpg: '',
        webp: '',
      };
    } else {
      return path;
    }
  }

  static truncate(s: string, n: number) {
    return s;
    if (!s) { return s; }
    s = s.replace(/<\/?[^>]+(>|$)/g, ''); // strip HTML Chars
    if (s.length <= n) { return s; }
    const subString = s.substr(0, n - 1);
    return subString.substr(0, subString.lastIndexOf(' ')) + '...';
  }

  constructor() { }

  public convert2TypedArray<T>(array: T[], type: (new () => T)): T[] {
    const newArray: T[] = new Array();

    if (!array || array.length === 0) {
      return [];
    }

    array.forEach(elem => {
      newArray.push(Object.assign(new type(), elem));
    });
    return newArray;
  }


}
