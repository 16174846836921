<div class="mt-4 rounded-larger shadow-none p-4 w-full">
  <h2 class="text-center sm:text-left text-gray-800 text-3xl font-bold">Nächste Events</h2>

  <div *ngIf="events?.length > 0" class="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mt-4">
    <div class="flex-1 md:w-1/3" *ngFor="let event of events">
      <div class="flex flex-col bg-gray-100 rounded-larger shadow-lg h-full">
        <div
          class="flex flex-row lg:flex-row items-center lg:space-x-2 justify-between ">
          <div class="py-2 lg:py-4 px-4 md:px-8 flex flex-col truncate">
            <div class="text-sm text-gray-600">{{ event.ageClass.name }}</div>
            <div class="font-bold text-lg md:text-xl text-orangeDark truncate">
              {{ event.name }}
            </div>
            <div class="text-sm text-gray-600">
              {{ event.getDateText() }}, <span>{{event.getTimeText()}}</span>
            </div>
          </div>
        </div>
        <div *ngIf="event.entryWithoutRegistration" class="flex items-center justify-center gap-2.5 bg-gray-200 font-bold rounded-b-larger text-sm py-1.5 text-gray-800 h-full">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-gray-700">
            <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
          </svg>          
          <span>Teilnahme ohne Voranmeldung</span>
        </div>
        <button [routerLink]="['/events', 'anmelden', event.id]" *ngIf="event.showExtraPage" class="bg-gray-200 hover:bg-gray-300 duration-100 transition-all py-1.5 text-gray-800 rounded-b-larger text-sm font-bold flex items-center justify-center gap-2.5">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-gray-700">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z" />
          </svg>                           
          <span>Jetzt anmelden</span>
        </button>
      </div>
    </div>

  </div>
  <div class="mt-2 text-gray-600 text-sm flex flex-col items-center" *ngIf="events?.length === 0">
    <svg class="w-24 h-24" viewBox="0 0 96 96" fill="#4B5563">
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          d="M9,44C8.2,44 7.5,43.7 6.9,43.1C6.3,42.5 6,41.8 6,41L6,10C6,9.2 6.3,8.5 6.9,7.9C7.5,7.3 8.2,7 9,7L12.25,7L12.25,4L15.5,4L15.5,7L32.5,7L32.5,4L35.75,4L35.75,7L39,7C39.8,7 40.5,7.3 41.1,7.9C41.7,8.5 42,9.2 42,10L42,41C42,41.8 41.7,42.5 41.1,43.1C40.5,43.7 39.8,44 39,44L9,44ZM9,41L39,41L39,19.5L9,19.5L9,41ZM9,16.5L39,16.5L39,10L9,10L9,16.5ZM9,16.5L9,10L9,16.5ZM14,27L14,24L34,24L34,27L14,27ZM14,36L14,33L27.95,33L27.95,36L14,36Z"
          style="fill-rule:nonzero;" />
      </g>
    </svg>
    <div class="mt-2">Die nächsten Termine kommen bestimmt!</div>
  </div>

  <div class="flex mt-4 flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 items-center justify-center">
    <button class="text-sm mt-1 bg-orange text-white px-6 py-3 font-bold rounded-full shadow-md"
      [routerLink]="['/events']">
      <span>Alle Events anzeigen</span>
    </button>
  </div>
</div>