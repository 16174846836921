import { Component, OnChanges, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";

@Component({
    selector: "ts-nav",
    templateUrl: "./nav.component.html",
    styleUrls: ["./nav.component.css"],
    standalone: true,
    imports: [RouterLink],
})
export class NavComponent {
  constructor() {}
}
