import { Component, Input, OnInit } from '@angular/core';
import { Course } from '../../models/course';
import { CourseService } from '../../services/course.service';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'ts-infosystem-featured-courses',
    templateUrl: './infosystem-featured-courses.component.html',
    styleUrls: ['./infosystem-featured-courses.component.css'],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class InfosystemFeaturedCoursesComponent implements OnInit {

  @Input() schoolId: number;
  @Input() showInOverview: boolean;
  courses: Course[] = [];

  constructor(
    private courseSrv: CourseService,
  ) { }

  updateCourses() {
    this.courseSrv.featuredCourses(this.schoolId).subscribe((courses) => {
      this.courses = courses
        .filter((course) => course?.course_start_dates.length > 0)
        .slice(0, 3);
    });
  }

  ngOnInit(): void {
    this.updateCourses();
  }

}
