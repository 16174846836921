<div class="w-full h-full rounded-2xl shadow-lg bg-orange p-4 flex flex-col">
  <span class="font-bold text-base text-orangeLightX">Veranstaltungen</span>
  <ul class="flex flex-col divide-y divide-orangeLight">
    <li class="flex gap-2 py-2" *ngFor="let event of events">
      <div class="flex w-36 shrink-0 flex-col">
        <span class="text-white font-bold text-3xl">{{
          event.date | date: "shortDate"
          }}</span>
        <span class="text-orangeLightX text-xl">{{
          event.getTimeText()
          }}</span>
      </div>
      <div class="flex flex-col overflow-hidden whitespace-nowrap text-ellipsis max-w-max">
        <div class="text-3xl text-white font-bold ">{{
          event.name
          }}</div>
        <div class="text-orangeLightX text-xl">{{
          event.ageClass.name
          }}</div>
      </div>
    </li>
  </ul>
</div>
