import { isPlatformBrowser, NgFor } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FooterIcon } from '../../models/footerIcon';
import { School } from '../../models/school';
import { FooterIconService } from '../../services/footer-icon.service';
import { SchoolService } from '../../services/school.service';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'ts-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],
    standalone: true,
    imports: [RouterLink, NgFor]
})
export class FooterComponent implements OnInit {

  public school: School = new School();
  public footerIcons: FooterIcon[] = [];

  constructor(
    private schoolSrv: SchoolService,
    private footerIconSrv: FooterIconService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  openLink(link: string) {
    if (isPlatformBrowser(this.platformId)) {
      window.open(link, '_blank').focus();
    }
  }

  ngOnInit() {
    this.schoolSrv.current().subscribe(school => {
      this.school = school;
      this.footerIconSrv.findBySchool(this.school.id).subscribe(footerIcons => this.footerIcons = footerIcons);
    });
  }

}
