import { Expose, Type } from "class-transformer";
import { IsArray, IsBoolean, IsEnum, IsOptional, IsString, ValidateNested } from "class-validator";
import { BackendDataType } from "./backend-data-type.enum";

class EnumValue {
  @IsString()
  @Expose()
  label: string;

  @IsString()
  @Expose()
  value: string;
}

export class BackendColumnFullSizeDto<T> {

  @IsString()
  @Expose()
  dbName: keyof T;

  @IsString()
  @Expose()
  prettyName: string;

  @IsBoolean()
  @Expose()
  isVisibleInTable: boolean;

  @IsBoolean()
  @Expose()
  required: boolean;

  @IsBoolean()
  @Expose()
  hideInJoinManyField?: boolean;

  @IsEnum(() => BackendDataType)
  @Expose()
  dataType: BackendDataType;

  @IsString()
  @IsOptional()
  @Expose()
  refTable?: string;

  /* @IsString()
  @IsOptional()
  @Expose()
  joinTable?: string; */

  @Expose()
  data?: any;

  @Expose()
  component?: any;

  @IsArray()
  @Expose()
  @ValidateNested({ each: true }) 
  @Type(() => EnumValue)
  enumValues?: EnumValue[];
}