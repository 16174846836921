import { NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AgeClass } from '../../models/ageClass';
import { AgeClassService } from '../../services/age-class.service';
import { PictureComponent } from '../../shared/picture/picture.component';

@Component({
    selector: 'ts-course-cards',
    templateUrl: './course-cards.component.html',
    styleUrls: ['./course-cards.component.css'],
    standalone: true,
    imports: [NgFor, PictureComponent, RouterLink],
    host: {ngSkipHydration: 'true'},
})
export class CourseCardsComponent implements OnInit {

  public ageClasses: AgeClass[];

  constructor(private ageClassSrv: AgeClassService) { }

  ngOnInit() {
    this.ageClassSrv.frontPage().subscribe((ageClasses) => {
      this.ageClasses = ageClasses;
    });
  }

}
