import { Component, OnInit } from '@angular/core';
import { AgeClassService } from '../../services/age-class.service';
import { AgeClass } from '../../models/ageClass';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { NgFor } from '@angular/common';

@Component({
    selector: 'ts-event-sidenav-menu',
    templateUrl: './event-sidenav-menu.component.html',
    styleUrls: ['./event-sidenav-menu.component.css'],
    standalone: true,
    imports: [NgFor, RouterLink, MatIcon]
})
export class EventSidenavMenuComponent implements OnInit {

  public ageClasses: AgeClass[];

  constructor(private ageClassSrv: AgeClassService) { }

  ngOnInit() {
    this.ageClassSrv.frontPage().subscribe((ageClasses) => {
      this.ageClasses = ageClasses;
    });
  }

}
