import * as dayjs from 'dayjs';
import { ConvService } from '../services/conv.service';
import { DbObj } from './dbObj';

export class News implements DbObj {

  public id: number;
  public urlTitle: string;
  public title: string;
  public image_name: any;
  public teaser: string;
  public text: string;
  public publish_start: Date;
  public publish_end: Date;
  public cta_active: boolean;
  public cta_link: string;
  public cta_text: string;
  private fk_school: number;

  get prettyName() {
    return this.title;
  }

  get subtitle() {
    return '<i>' + dayjs(this.publish_start).format('DD.MM.YYYY') + '</i> - ' + ConvService.truncate(this.teaser, 30);
  }

  constructor() { }
}
