import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Dance } from "../models/dance";
import { ConvService } from "./conv.service";
import { CourseService } from "./course.service";
import { CrudService } from "./crud.service";
import { TsHttpService } from "./ts-http.service";

@Injectable({
  providedIn: "root",
})
export class DanceService extends CrudService<Dance> {
  static prefix = "dances";
  dbTable = "dance";

  constructor(
    public httpSrv: TsHttpService,
    public convServ: ConvService,
    public courseSrv: CourseService
  ) {
    super(httpSrv, DanceService.prefix, Dance, convServ);
  }

  findBySchool(schoolId: number): Observable<Dance[]> {
    return this.httpSrv.get<Dance[]>(`schools/${schoolId}/dances`);
  }

  findByName(name: string, schoolId: number) {
    return this.httpSrv
      .get<Dance>(`schools/${schoolId}/dances/names/${name}`)
      .pipe(
        map((dance) => {
          dance = Object.assign(new Dance(), dance);
          dance.courses = this.courseSrv.convertCourses(dance.courses);
          return dance;
        })
      );
  }
}
