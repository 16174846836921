import { NgIf } from "@angular/common";
import { Component, forwardRef, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";
import { InfosystemIndexComponent } from "./infosystem/infosystem-index/infosystem-index.component";
import { School } from "./models/school";
import { FooterComponent } from "./navigation/footer/footer.component";
import { SidenavComponent } from "./navigation/sidenav/sidenav.component";
import { ToolbarComponent } from "./navigation/toolbar/toolbar.component";
import { RootComponent } from "./online-tanzspass/root/root.component";
import { AuthService } from "./services/auth.service";
import { NewsService } from "./services/news.service";
import { SchoolService } from "./services/school.service";
import { SeoService } from "./services/seo.service";
import { TanzspassComComponent } from "./tanzspass-com/tanzspass-com.component";
@Component({
    selector: "ts-app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"],
    standalone: true,
    imports: [
        NgIf,
        ToolbarComponent,
        RouterOutlet,
        RootComponent,
        InfosystemIndexComponent,
        SidenavComponent,
        FooterComponent,
        TanzspassComComponent,

    ],
})
export class AppComponent implements OnInit {
  title = "Tanzspass";
  sidenavOpen: boolean;
  pageMode: PageMode;
  // public pageType: PageType = PageType.MAINPAGES;
  public schools: School[] = new Array();
  seoData: Subject<any> = new BehaviorSubject({});

  constructor(
    @Inject(forwardRef(() => SchoolService))
    private schoolSrv: SchoolService,

    @Inject(forwardRef(() => AuthService))
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    
    @Inject(forwardRef(() => SeoService))
    private seoSrv: SeoService,

    @Inject(forwardRef(() => NewsService))
    private newsSrv: NewsService
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        this.seoData.next(event);
      });
  }

  openSidenav() {
    this.sidenavOpen = true;
  }

  closeSidenav() {
    this.sidenavOpen = false;
  }

  ngOnInit() {
    this.authService.autoLogin();
    this.pageMode = this.schoolSrv.pageMode;

    // Debug //
    //this.pageMode = PageMode.tv;

    this.schoolSrv.findAll().subscribe((schools) => {
      this.schools = schools;
    });

    this.schoolSrv.current().subscribe((school) => {
      this.seoData.subscribe((seoData) => {
        if (seoData["title"]) {
          this.seoSrv.updateTitle(
            seoData["title"].replace("{SCHOOL}", school.name)
          );
        } else {
          this.seoSrv.updateTitle(
            school.name + " | Tanzspass in " + school.city
          );
        }

        if (seoData["ogUrl"]) {
          this.seoSrv.updateOgUrl(
            `https://${school.homepage_url}${seoData["ogUrl"]}`
          );
        } else {
          this.seoSrv.updateOgUrl(`https://${school.homepage_url}`);
        }

        if (seoData["imgUrl"]) {
          this.seoSrv.updateImage(seoData["imgUrl"]);
        } else {
          this.newsSrv.findBySchool(school.id).subscribe((news) => {
            this.seoSrv.updateImage(
              `https://api.tanzspass.com/assets/${news[0]?.image_name}`
            );
          });
        }

        if (seoData["description"]) {
          this.seoSrv.updateDescription(
            seoData["description"].replace("{SCHOOL}", school.name)
          );
        } else {
          this.seoSrv.updateDescription(
            "Tanzen bei uns in " +
              school.city +
              " ♫ Egal ob jung oder alt, ob im Paar oder solo - " +
              "unsere riesige Kursauswahl bietet für jeden etwas ✓ Jetzt Tanzkurse buchen!"
          );
        }
      });
    });
  }
}

export enum PageMode {
  tv = "tv",
  schools = "schools",
  tanzspass_com = "tanzspass_com",
  online = "online",
}
