<div *ngIf="rss" class="relative w-full rounded-2xl shadow-lg overflow-hidden flex flex-col row-span-1 col-span-1">
  <img [src]="getRssImgUrl(rssIndex)" alt="" />

  <div class="absolute bg-translucent inset-0 flex flex-col justify-end text-white p-4">
    <span class="absolute p-1 text-white top-4 right-4">{{
      rss[rssIndex].date | date: "dd.MM. HH:mm"
      }}</span>
    <span class="font-bold text-3xl">{{ rss[rssIndex].title }}</span>
    <span class="text-sm mt-2">{{ rss[rssIndex].rights }}</span>
  </div>
</div>
