<!-- desktop -->
<div class="hidden sm:block relative w-full h-auto sm:mt-8 sm:rounded-larger sm:shadow-lg overflow-hidden"
  *ngIf="currentNews">
  <ts-picture class="object-cover w-full h-full" [img]="currentNews?.image_name" width="100%" height="100%">
  </ts-picture>

  <div
    class="sm:absolute sm:bottom-12 sm:left-12 space-y-4 sm:bg-translucent p-4 sm:p-8 shadow-sm sm:rounded-larger sm:text-white">
    <h1 class="text-xl sm:text-4xl font-bold">{{ currentNews?.title }}</h1>
    <p class="text-sm mt-6 max-w-prose">
      {{ currentNews?.teaser }}
    </p>
    <button class="rounded-full px-5 py-2 bg-translucentLighter hover:bg-translucent text-sm"
      [routerLink]="['/news', currentNews?.urlTitle]">
      Mehr erfahren
    </button>
  </div>
</div>

<!-- mobile -->
<div class="waves sm:hidden relative shadow-xl sm:rounded-2xl sm:overflow-hidden h-72 sm:mt-8 p-8 mx-0 sm:mx-2">
  <div class="absolute inset-0">
    <ts-picture class="object-cover w-full h-full" [img]="currentNews?.image_name" width="100%" height="100%">
    </ts-picture>
    <div class="absolute inset-0"></div>
  </div>
</div>
<div class="sm:hidden mt-4 relative h-full flex flex-col justify-center items-center">
  <h1 class="text-center text-2xl font-extrabold tracking-tight sm:text-3xl lg:text-5xl text-orange">
    {{ currentNews?.title }}
  </h1>
  <p class="text-xs mt-3 max-w-prose text-orangeLight">
    {{ currentNews?.teaser }}
  </p>
  <button class="sm:hidden block rounded-full px-3 py-1.5 bg-orange shadow-md text-white text-sm font-bold mt-6"
    [routerLink]="['/news', currentNews?.urlTitle]">
    Mehr erfahren
  </button>
</div>
