import { isPlatformBrowser } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { tap } from "rxjs/operators";
import { School } from "../models/school";
import { TsHttpService } from "./ts-http.service";

export interface AuthResponseData {
  expiresIn: number;
  accessToken: string;
  school: School;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  static prefix = "auth";
  school = new BehaviorSubject<School>(null);
  private tokenExpirationTimer: any;

  constructor(
    private httpSrv: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  login(username: string, password: string) {
    return this.httpSrv
      .post<AuthResponseData>(`${TsHttpService.root}${AuthService.prefix}`, {
        username,
        password,
      })
      .pipe(
        tap((resData) => {
          this.handleAuthentication(
            resData.school,
            resData.accessToken,
            resData.expiresIn
          );
        })
      );
  }

  autoLogin() {
    let schoolData = null;
    if (isPlatformBrowser(this.platformId)) {
      schoolData = JSON.parse(localStorage.getItem("school"));
    }
    if (!schoolData) {
      return;
    }

    const loadedSchool = Object.assign(new School(), schoolData);

    if (loadedSchool.token) {
      this.school.next(loadedSchool);
      const expirationDuration =
        new Date(schoolData._tokenExpirationDate).getTime() -
        new Date().getTime();
      this.autoLogout(expirationDuration);
    }
  }

  logout() {
    this.school.next(null);
    if (isPlatformBrowser(this.platformId)) {
      this.router.navigate(["/login"]);
      localStorage.removeItem("school");
    }
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }

  autoLogout(expirationDuration: number) {
    if (isPlatformBrowser(this.platformId)) {
      this.tokenExpirationTimer = setTimeout(() => {
        this.logout();
      }, expirationDuration);
    }
  }

  private handleAuthentication(
    schoolCandidate: School,
    token: string,
    expiresIn: number
  ) {
    const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
    const school = Object.assign(new School(), schoolCandidate);
    school.tokenExpirationDate = expirationDate;
    school.token = token;

    this.school.next(school);
    this.autoLogout(expiresIn * 1000);
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem("school", JSON.stringify(school));
    }
  }
}
