import { Injectable } from "@angular/core";
import { TvSlide } from "../models/tvSlide";
import { ConvService } from "./conv.service";
import { CrudService } from "./crud.service";
import { TsHttpService } from "./ts-http.service";

@Injectable({
  providedIn: "root",
})
export class TvSlideService extends CrudService<TvSlide> {
  static prefix = "tv-slide";
  static slideDuration = 15000;
  static newsCount: number = 3;
  dbTable = "tv_slide";

  constructor(public httpSrv: TsHttpService, public convSrv: ConvService) {
    super(httpSrv, TvSlideService.prefix, TvSlide, convSrv);
  }
}
