import { ConvService } from '../services/conv.service';
import { DbObj } from './dbObj';

export class ImageMovie implements DbObj {

    public id: number;
    public title: string;
    public text: string;
    public youtube_video_id: string;
    public added: Date;
    private fk_school: number;

    constructor() { }

    get prettyName() {
        return this.title;
    }

    get subtitle() {
        return ConvService.truncate(this.text, 30);
    }
}
