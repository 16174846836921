<div *ngFor="let ageClass of ageClasses"
  class="flex flex-row sm:flex-col flex-1 mt-4 items-center sm:items-stretch  mx-2 sm:mx-0">
  <ts-picture class="h-48 w-48 sm:w-full object-cover overflow-hidden cursor-pointer flex-shrink-0"
    [routerLink]="['/kurse', ageClass.nameUrl]" [img]="ageClass?.imgFrontpage">
  </ts-picture>

  <div class="p-4 flex flex-col justify-between space-y-2 items-start h-full">
    <div class="flex flex-col">
      <h2 class="text-2xl font-lg font-bold text-gray-700">
        {{ ageClass.nameTitle }}
      </h2>
      <p class="mt-2 text-sm text-gray-600" [innerHTML]="ageClass.teaser"></p>
    </div>
    <a [routerLink]="['/kurse', ageClass.nameUrl]"
      class="text-sm mt-2 bg-orange text-white px-3 py-1.5 font-bold rounded-full shadow-md">Kurse {{
      ageClass.prettyName }}</a>
  </div>
</div>
