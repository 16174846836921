import { Component, Input, OnInit } from '@angular/core';
import { Course } from '../../models/course';
import { News } from '../../models/news';
import { Room } from '../../models/room';
import { CourseService } from '../../services/course.service';
import { NewsService } from '../../services/news.service';
import { PictureComponent } from '../../shared/picture/picture.component';
import { NgIf, NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'ts-infosystem-upcomming-courses',
    templateUrl: './infosystem-upcomming-courses.component.html',
    styleUrls: ['./infosystem-upcomming-courses.component.css'],
    standalone: true,
    imports: [NgIf, NgFor, PictureComponent, DatePipe]
})
export class InfosystemUpcommingCoursesComponent implements OnInit {

  @Input() schoolId: number;
  upcommingCourses: {
    room: Room;
    currentCourse: Course;
    nextCourse: Course;
  }[] = [];
  news: News[] = [];

  constructor(
    private courseSrv: CourseService,
    private newsSrv: NewsService,
  ) { }

  updateCourses() {
    this.courseSrv.upcommingCourses(this.schoolId).subscribe((data) => {
      this.upcommingCourses = data.filter(
        (room) => room.currentCourse || room.nextCourse
      );
    });
  }


  updateNews() {
    this.newsSrv.bySchoolId(this.schoolId).subscribe((news) => {
      this.news = news;
    });
  }

  ngOnInit(): void {
    this.updateCourses();
    this.updateNews();
  }

}
