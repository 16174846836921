import { Component, ElementRef, Input } from "@angular/core";
import { EventManager } from "@angular/platform-browser";
import { fromEvent, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConvService } from "../../services/conv.service";
import { NgClass } from "@angular/common";
import { ImgFallbackModule } from "ngx-img-fallback";

@Component({
    selector: "ts-picture",
    templateUrl: "./picture.component.html",
    styleUrls: ["./picture.component.css"],
    standalone: true,
    imports: [ImgFallbackModule, NgClass],
})
export class PictureComponent {
  constructor(private el: ElementRef, private eventManager: EventManager) {}

  @Input() width: string;
  @Input() height: string;
  @Input() alt: string;
  @Input() isInCard: boolean;

  private _img: {
    jpg: string;
    webp: string;
  };

  @Input() set img(val: any) {
    if (val) {
      this._img = ConvService.convAssetPath(val);
    }
  }

  get img() {
    return this._img;
  }

  getImgSize(imageSrc: string): Observable<any> {
    const mapLoadedImage = (event) => {
      return {
        width: event.target.width,
        height: event.target.height,
      };
    };
    const image: HTMLImageElement = new Image();
    const $loadedImg = fromEvent(image, "load").pipe(map(mapLoadedImage));
    image.src = imageSrc;
    return $loadedImg;
  }
}
