<div class="flex flex-col">
  <h3 class="text-gray-500 pl-2 text-sm mt-2">Die Tanzschule</h3>
  <a class="hover:bg-orangeLightX text-gray-700 py-2 pl-2 font-bold flex items-center gap-2 text-sm" routerLink="about"
    *ngIf="school?.aboutUsText">
    <mat-icon aria-hidden="true">home</mat-icon>
    Über uns
  </a>
  <a class="hover:bg-orangeLightX text-gray-700 py-2 pl-2 font-bold flex items-center gap-2 text-sm" routerLink="team"
    *ngIf="hasTeammembers">
    <mat-icon aria-hidden="true">&#xe7ef;</mat-icon>
    Team
  </a>
  <a class="hover:bg-orangeLightX text-gray-700 py-2 pl-2 font-bold flex items-center gap-2 text-sm" routerLink="saele">
    <mat-icon aria-hidden="true">&#xeb4f;</mat-icon>
    Säle
  </a>
  <a class="hover:bg-orangeLightX text-gray-700 py-2 pl-2 font-bold flex items-center gap-2 text-sm"
    *ngIf="hasImageMovies" routerLink="filme">
    <mat-icon aria-hidden="true">&#xe02c;</mat-icon>
    Filme
  </a>
  <a class="hover:bg-orangeLightX text-gray-700 py-2 pl-2 font-bold flex items-center gap-2 text-sm" *ngIf="hasPartners"
    routerLink="partner">
    <mat-icon aria-hidden="true">&#xe0af;</mat-icon>
    Unsere Werbepartner
  </a>
  <a class="hover:bg-orangeLightX text-gray-700 py-2 pl-2 font-bold flex items-center gap-2 text-sm"
    routerLink="kontakt">
    <mat-icon aria-hidden="true">&#xe55b;</mat-icon>
    Kontakt und Anfahrt
  </a>
  <h3 class="text-gray-500 pl-2 text-sm mt-2">Kundenbereich</h3>
  <a class="hover:bg-orangeLightX text-gray-700 py-2 pl-2 font-bold flex items-center gap-2 text-sm"
    [routerLink]="['kundenbereich', 'videos']">
    <mat-icon aria-hidden="true">&#xe02c;</mat-icon>
    Mediathek
  </a>
  <a class="hover:bg-orangeLightX text-gray-700 py-2 pl-2 font-bold flex items-center gap-2 text-sm"
    *ngIf="school.has_gallery" [routerLink]="['kundenbereich', 'galerie']">
    <mat-icon aria-hidden="true">&#xe411;</mat-icon>
    Fotogalerie
  </a>
  <a class="hover:bg-orangeLightX text-gray-700 py-2 pl-2 font-bold flex items-center gap-2 text-sm"
    [routerLink]="['kundenbereich', 'kursprogramm']">
    <mat-icon aria-hidden="true">&#xe415;</mat-icon>
    Kursprogramm
  </a>
  <a class="hover:bg-orangeLightX text-gray-700 py-2 pl-2 font-bold flex items-center gap-2 text-sm"
    [routerLink]="['kundenbereich', 'info']">
    <mat-icon aria-hidden="true">info</mat-icon>
    Aktuelle Info
  </a>
  <!--<a [routerLink]="['kuendigung']">
  <mat-icon aria-hidden="true">&#xe615;</mat-icon>
  Kündigen
</a>-->
</div>
