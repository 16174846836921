import { NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { AgeClass } from '../../models/ageClass';
import { School } from '../../models/school';
import { AgeClassService } from '../../services/age-class.service';
import { SchoolService } from '../../services/school.service';
import { TsHttpService } from '../../services/ts-http.service';

@Component({
    selector: 'ts-course-sidenav-menu',
    templateUrl: './course-sidenav-menu.component.html',
    styleUrls: ['./course-sidenav-menu.component.css'],
    standalone: true,
    imports: [NgFor, RouterLink, MatIcon],
    host: {ngSkipHydration: 'true'},
})
export class CourseSidenavMenuComponent implements OnInit {

  public ageClasses: AgeClass[];
  public school: School = new School();
  assetsRoot = TsHttpService.assetsRoot;

  constructor(private ageClassSrv: AgeClassService, private schoolSrv: SchoolService) { }

  ngOnInit() {
    this.schoolSrv.current().subscribe((school) => {
      this.school = school;
      this.ageClassSrv.findAll().subscribe((ageClasses) => {
        this.ageClasses = ageClasses.filter((ageClass) => {
          return this.school.show_holiday_courses || ageClass.nameUrl !== 'ferienprogramm';
        });
      });
    });
  }
}
