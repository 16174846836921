import { animate, style, transition, trigger } from "@angular/animations";

export function routerTransition() {
  return trigger("routerTransition", [
    transition(":enter", [
      // before 2.1: transition('void => *', [
      style({ opacity: "1" }),
      animate(".2s ease-in-out", style({ opacity: "1" })),
    ]),
    transition(":leave", [
      // before 2.1: transition('* => void', [
      style({ opacity: "1" }),
      animate(".2s ease-in-out", style({ opacity: "1" })),
    ]),
  ]);
}
