<div class="w-full h-screen flex flex-col md:flex-row">
  <img src="/assets/img/beach_big.jpg" class="object-cover md:object-right w-full h-full absolute inset-0">
  <img class="absolute z-10 top-5 left-5 w-12 md:w-[72px]" src="/assets/img/tanzspass_black_144.png">
  <div class="absolute right-8 top-20 md:top-1/4 max-w-[360px] sm:max-w-[420px] text-white text-right">
    <h1 class="text-4xl sm:text-5xl md:text-6xl font-extrabold">Deine Tanzschulen im Hamburger Westen</h1>
  </div>
  <div class="absolute bottom-8 right-0 overflow-x-auto overflow-y-hidden w-full">
    <div class="flex flex-row-reverse gap-5 px-8 overflow-x-auto">
      @for (school of schools; track school.id) {
      <a [href]="'https://' + school.homepage_url" class="relative flex items-center justify-center shrink-0 h-64 sm:h-80 w-52 overflow-hidden rounded-xl shadow-md hover:shadow-2xl transition-all hover:brightness-110 hover:scale-[98%]">
        <ts-picture class="object-cover w-full h-full absolute inset-0" [img]="school.imgRoomTeaser">
        </ts-picture>
        <div class="text-white bg-gradient-to-b from-transparent to-translucent p-5 pt-10 absolute bottom-0 flex flex-col gap-2">
          <span class="text-sm">{{ school.city }}</span>
          <h2 class="text-lg font-bold leading-6">{{ school.name }}</h2>
        </div>
      </a>
      }
    </div>
  </div>
  <div class="absolute bottom-2.5 left-2.5 flex items-center gap-2.5 text-white">
    @for (school of schools; track school.id) {
      <a class="hover:underline text-xs" [href]="'https://'+ school.homepage_url + '/datenschutz'">Datenschutz {{capitalizeFirstLetter(school.short_name)}}</a>
    }
  </div>
</div>