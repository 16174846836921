
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Event } from '../models/event';
import { ConvService } from './conv.service';
import { CrudService } from './crud.service';
import { TsHttpService } from './ts-http.service';


@Injectable({
  providedIn: "root",
})
export class EventService extends CrudService<Event> {

  static prefix = 'events';
  dbTable = 'event';

  constructor(
    public httpSrv: TsHttpService,
    public convServ: ConvService
  ) {
    super(httpSrv, EventService.prefix, Event, convServ);
  }

  public bySchoolId(schoolId: number, fromNow: boolean) {
    return this.httpSrv
      .get<Event[]>(`schools/${schoolId}/${EventService.prefix}`).pipe(
        map(events => this.convServ.convert2TypedArray<Event>(events, Event)));
  }

  public last(schoolId: number, count: number) {
    const request = `schools/${schoolId}/${EventService.prefix}`;
    return this.httpSrv
      .get<Event[]>(request).pipe(
        map(events => this.convServ.convert2TypedArray<Event>(events, Event)));
  }

  public byAgeClass(ageClassId: number, schoolId: number) {
    return this.httpSrv
      .get<Event[]>(`schools/${schoolId}/ageClasses/${ageClassId}/${EventService.prefix}`).pipe(
        map(events => this.convServ.convert2TypedArray<Event>(events, Event)));
  }

  exportXlsx(id: number) {
    return this.httpSrv.get(`${EventService.prefix}/${id}.xlsx`, { responseType: 'blob' });
  }
}
