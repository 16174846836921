import { isPlatformBrowser, NgIf, NgFor } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { switchMap, timer } from 'rxjs';
import { TsHttpService } from '../../services/ts-http.service';

@Component({
    selector: 'ts-infosystem-hvv',
    templateUrl: './infosystem-hvv.component.html',
    styleUrls: ['./infosystem-hvv.component.css'],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class InfosystemHvvComponent implements OnInit {

  @Input() stationName: string;
  @Input() schoolId: number;
  departures: any[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private httpSrv: TsHttpService,
  ) { }

  fetchHvv() {
    if (isPlatformBrowser(this.platformId)) {
      timer(0, 5000)
        .pipe(
          switchMap(() => this.httpSrv.get(`hvv/departures/${this.schoolId}`))
        )
        .subscribe((data: any) => {
          this.departures = data.departures;
        });
    }
  }

  ngOnInit(): void {
    this.fetchHvv();
  }

}
