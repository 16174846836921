import { Course } from "./course";
import { CourseLevel } from "./courseLevel";
import { DbObj } from "./dbObj";

export class Dance implements DbObj {
  public id: number;
  public name: string;
  public name_url: string;
  public shortText: string;
  public text: string;
  public courses: Course[];
  public courseLevels: CourseLevel[];
  public style: string;
  public spotify_playlist: string | null;

  constructor() {}

  get prettyName() {
    return this.name;
  }

  get subtitle() {
    return this.name_url;
  }
}
