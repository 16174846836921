<mat-sidenav-container class="flex flex-col">
  <mat-sidenav id="sidenav" #sidenav mode="over" [fixedInViewport]="true" (openedChange)="openedChanged($event)">
    <div class="flex flex-col">
      <div class="flex flex-col group cursor-pointer">
        <h2 routerLink="/" (click)="sidenav.close()" class="font-bold text-xl p-4">
          {{ school.name }}
        </h2>
        <a class="group-hover:bg-orangeLightX text-gray-700 py-2 pl-2 font-bold flex items-center gap-2 text-sm"
          routerLink="/" (click)="sidenav.close()">Home</a>
      </div>
      <ts-course-sidenav-menu (click)="sidenav.close()"></ts-course-sidenav-menu>
      <a class="hover:bg-orangeLightX text-gray-700 py-2 pl-2 font-bold flex items-center gap-2 text-sm"
        [href]="school?.voucher_url" target="_blank" (click)="sidenav.close()">
        <mat-icon>&#xe8f6;</mat-icon>
        Gutscheine
      </a>
      <ts-event-sidenav-menu (click)="sidenav.close()"></ts-event-sidenav-menu>
      <!--<ts-page-sidenav-menu (click)="sidenav.close()"></ts-page-sidenav-menu>-->
      <ts-school-sidenav-menu (click)="sidenav.close()"></ts-school-sidenav-menu>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
