<div class="bg-zinc-700 text-zinc-100 py-3 text-xl px-6 flex justify-between">

  <!-- left -->
  <div class="font-bold flex items-center">
    <span>{{ room?.name }}</span>
  </div>

  <!-- middle -->
  <div *ngIf="!room.showAllCoursesInToolbar" class="flex items-baseline">
    <div *ngIf="room.currentCourse && room.currentCourse.id" class="flex items-baseline">
      <span class="text-zinc-200 text-sm">jetzt</span>
      <span class="font-bold text-white ml-2">{{
        room.currentCourse.description
        }}</span>
      <span class="text-zinc-200 text-sm ml-2">{{
        room.currentCourse.courseLevel.ageClass.nameTitle
        }}</span>
    </div>
    <div *ngIf="room.nextCourse && room.nextCourse.id" class="flex items-baseline">
      <svg *ngIf="
      room.currentCourse && room.currentCourse.id && room.nextCourse && room.nextCourse.id
        " xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-6 text-zinc-300 self-center" fill="none"
        viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
      </svg>
      <span class="text-zinc-200 text-sm ml-6">{{
        room.timeUntilNextCourse
        }}</span>
      <span class="font-bold text-white ml-2">{{
        room.nextCourse.description
        }}</span>
      <span class="text-zinc-200 text-sm ml-2">{{
        room.nextCourse.courseLevel.ageClass.nameTitle
        }}</span>
    </div>
  </div>
  <div *ngIf="room.showAllCoursesInToolbar" class="flex gap-16 divide-gray-200">
    <div *ngFor="let currentRoom of rooms" class="bg-zinc-600 rounded-md overflow-hidden shadow-inner">
      <div *ngIf="currentRoom.currentCourse || currentRoom.nextCourse" class="flex items-center gap-2.5">
        <span class="font-bold uppercase py-1.5 px-3 bg-zinc-500">
          {{currentRoom.name}}
        </span>

        <div class="flex items-center gap-6">
          <!-- currentCourse -->
          <div *ngIf="currentRoom.currentCourse.id" class="flex items-center gap-2.5">
            <div class="text-sm rounded-md bg-zinc-500 py-0.5 px-1.5">bis {{currentRoom.currentCourse.time_end|
              date:'HH:mm'}}
              Uhr
            </div>
            <div class="flex flex-col text-sm">
              <span class="font-bold">{{currentRoom.currentCourse.description}}</span>
              <span>{{currentRoom.currentCourse.courseLevel.ageClass.nameTitle}}</span>
            </div>
          </div>

          <!-- nextCourse -->
          <div class="flex items-center gap-2.5">
            <div class="text-sm rounded-md bg-zinc-500 py-0.5 px-1.5">ab {{currentRoom.nextCourse.time | date:'HH:mm'}}
              Uhr</div>
            <div class="flex flex-col text-sm">
              <span class="font-bold">{{currentRoom.nextCourse.description}}</span>
              <span>{{currentRoom.nextCourse.courseLevel.ageClass.nameTitle}}</span>
            </div>
          </div>

          <div class="pr-0.5"></div>
        </div>
      </div>
    </div>
  </div>

  <!-- right -->
  <div class="font-bold flex items-center">
    <span>{{ time | date: "shortTime" }} Uhr</span>
  </div>
</div>
