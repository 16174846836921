import { Component, OnInit } from '@angular/core';
import { Course } from '../../models/course';
import { School } from '../../models/school';
import { CourseService } from '../../services/course.service';
import { SchoolService } from '../../services/school.service';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'ts-course-featured',
    templateUrl: './course-featured.component.html',
    styleUrls: ['./course-featured.component.css'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, MatIcon, MatTooltip]
})
export class CourseFeaturedComponent implements OnInit {

  public school = new School();
  public courses: Course[] = [];

  constructor(private schoolSrv: SchoolService, private courseSrv: CourseService) { }

  ngOnInit() {
    this.schoolSrv.current().subscribe(school => {
      this.school = school;
      this.courseSrv.featuredCourses(school.id).subscribe(courses => {
        this.courses = courses;
      });
    });
  }

}
