import { Component, OnInit } from '@angular/core';
import { FooterComponent } from '../../navigation/footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { NavComponent } from '../nav/nav.component';

@Component({
    selector: 'ts-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.css'],
    standalone: true,
    imports: [NavComponent, RouterOutlet, FooterComponent]
})
export class RootComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
