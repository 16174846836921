import { Component, Input, OnInit } from '@angular/core';
import { Event } from '../../models/event';
import { EventService } from '../../services/event.service';
import { TvSlideService } from '../../services/tv-slide.service';
import { NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'ts-infosystem-events',
    templateUrl: './infosystem-events.component.html',
    styleUrls: ['./infosystem-events.component.css'],
    standalone: true,
    imports: [NgFor, DatePipe]
})
export class InfosystemEventsComponent implements OnInit {

  @Input() schoolId: number;
  events: Event[] = [];

  constructor(
    private eventSrv: EventService,
  ) { }

  updateEvents() {
    this.eventSrv.last(this.schoolId, TvSlideService.newsCount).subscribe((events) => {
      this.events = events.slice(0, TvSlideService.newsCount);
    });
  }

  ngOnInit(): void {
    this.updateEvents();
  }

}
