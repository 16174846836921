import { DbObj } from './dbObj';
import { School } from './school';

export class FooterIcon implements DbObj {
    id: number;
    iconUrl: string;
    href: string;
    alt: string;
    school?: School;

    get prettyName() {
        return this.alt;
    }

    get subtitle() {
        return this.href;
    }
}