<nav class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
  <div
    class="w-full py-6 flex items-center justify-between border-b border-orange lg:border-none"
  >
    <div class="flex items-center">
      <a routerLink="/online/">
        <span class="sr-only">Workflow</span>
        <img
          class="h-10 w-auto"
          src="/assets/img/logos/tanzspass_wordmark_black.svg"
          alt=""
        />
      </a>
      <div class="hidden ml-10 space-x-8 lg:block">
        <a
          routerLink="/online/"
          class="text-base font-medium text-orange blur-md hover:text-orangeLight"
          key="home"
        >
          Home
        </a>
        <a
          routerLink="/online/preise"
          class="text-base font-medium text-orange blur-md hover:text-orangeLight"
          key="prices"
        >
          Preise
        </a>

        <a
          routerLink="/online/faq"
          class="text-base font-medium text-orange blur-md hover:text-orangeLight"
          key="faq"
        >
          FAQ
        </a>
      </div>
    </div>
    <div class="ml-10 space-x-4">
      <a
        routerLink="/online/login"
        class="inline-block bg-orange py-2 px-4 border border-transparent rounded-md text-base font-medium text-white hover:bg-orangeLight"
        >Anmelden</a
      >
      <a
        routerLink="/online/registrieren"
        class="inline-block bg-white py-2 px-4 border border-transparent rounded-md text-base font-medium text-orange hover:bg-gray-100"
        >Registrieren</a
      >
    </div>
  </div>
  <div class="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
    <a
      href="#"
      class="text-base font-medium text-orange hover:text-orangeLight"
      key="Solutions"
    >
      Solutions
    </a>

    <a
      href="#"
      class="text-base font-medium text-orange hover:text-orangeLight"
      key="Pricing"
    >
      Pricing
    </a>

    <a
      href="#"
      class="text-base font-medium text-orange hover:text-orangeLight"
      key="Docs"
    >
      Docs
    </a>

    <a
      href="#"
      class="text-base font-medium text-orange hover:text-orangeLight"
      key="Company"
    >
      Company
    </a>
  </div>
</nav>
