import { Component, OnInit } from '@angular/core';
import { AgeClass } from '../../models/ageClass';
import { Event } from '../../models/event';
import { AgeClassService } from '../../services/age-class.service';
import { EventService } from '../../services/event.service';
import { SchoolService } from '../../services/school.service';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'ts-event-next',
    templateUrl: './event-next.component.html',
    styleUrls: ['./event-next.component.css'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink]
})
export class EventNextComponent implements OnInit {

  public events: Event[];
  public ageClasses: AgeClass[];

  public newsCount = 3;

  constructor(private eventSrv: EventService, private schoolSrv: SchoolService, private ageClassSrv: AgeClassService) { }

  ngOnInit() {
    this.ageClassSrv.frontPage().subscribe((ageClasses) => {
      this.ageClasses = ageClasses;
      this.schoolSrv.current().subscribe((school) => {
        this.eventSrv.last(school.id, this.newsCount).subscribe((events) => {
          this.events = events.slice(0, this.newsCount);
        });
      });
    });
  }

}
