<div class="relative w-full h-auto sm:mt-8 sm:rounded-larger sm:shadow-lg overflow-hidden">
  <ts-picture class="object-cover w-full h-full" [img]="school.imgTeamTeaser">
  </ts-picture>
  <div
    class="sm:absolute sm:bottom-12 sm:left-12 space-y-4 sm:bg-translucent p-4 sm:p-8 shadow-sm sm:rounded-larger sm:text-white text-center sm:text-left">
    <h2 class="text-4xl font-bold text-orange sm:text-white">Das Team</h2>
    <p class="text-sm mt-6 max-w-prose">Wir heißen alle herzlich willkommen!</p>
    <button *ngIf="hasTeammembers"
      class="rounded-full px-3 py-1.5 shadow-md sm:shadow-none bg-orange sm:bg-translucentLighter text-white hover:bg-orangeLighter sm:hover:bg-translucent text-sm"
      [routerLink]="['/team']">
      Mehr erfahren
    </button>
  </div>
</div>
