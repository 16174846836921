import { ConvService } from '../services/conv.service';
import { DbObj } from './dbObj';

export class BusinessPartner implements DbObj {

    public id: number;
    public name: string;
    private _imgUrl: any;
    public link: string;
    public text: string;
    private fk_school: number;

    get prettyName() {
        return this.name;
    }

    get subtitle() {
        return ConvService.truncate(this.text, 30);
    }

    get imgUrl() { return ConvService.convAssetPath(this._imgUrl); }
    set imgUrl(val) { this._imgUrl = val; }

    constructor() { }
}
