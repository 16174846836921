import { Component, OnInit } from '@angular/core';
import { School } from '../../models/school';
import { SchoolService } from '../../services/school.service';
import { TeamService } from '../../services/team.service';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { PictureComponent } from '../../shared/picture/picture.component';

@Component({
    selector: 'ts-team-card',
    templateUrl: './team-card.component.html',
    styleUrls: ['./team-card.component.css'],
    standalone: true,
    imports: [PictureComponent, NgIf, RouterLink]
})
export class TeamCardComponent implements OnInit {

  public hasTeammembers = false;

  public school: School = new School();

  constructor(private schoolSrv: SchoolService, private teamSrv: TeamService) { }

  ngOnInit() {
    this.schoolSrv.current().subscribe(school => {
      this.school = school;
      this.teamSrv.findBySchool(this.school.id).subscribe(teammembers => {
        this.hasTeammembers = teammembers.length > 0;
      });
    });
  }

}
