<!-- desktop -->
<div class="hidden sm:block relative w-full h-auto sm:mt-8 sm:rounded-larger sm:shadow-lg overflow-hidden">
  <ts-picture class="object-cover w-full h-full" [img]="school.imgRoomTeaser" width="100%" height="100%">
  </ts-picture>

  <div
    class="sm:absolute sm:bottom-12 sm:left-12 space-y-4 sm:bg-translucent p-4 sm:p-8 shadow-sm sm:rounded-larger sm:text-white">
    <h2 class="text-4xl font-bold">
      {{ school.has_hall_rental ? "Die Säle" : "Die Tanzschule" }}
    </h2>
    <div class="text-sm mt-6 max-w-prose" *ngIf="school.has_hall_rental">
      <p>Sie lieben es, zu feiern und suchen nach einem passenden Ambiente?</p>
      <p>
        Ob Hochzeiten, Geburtstage, Betriebsfeiern oder Empfänge - bei uns ist
        alles möglich!
      </p>
    </div>
    <button class="rounded-full px-5 py-2 bg-translucentLighter hover:bg-translucent text-sm" [routerLink]="['/saele']">
      Mehr erfahren
    </button>
  </div>
</div>

<!-- mobile -->
<div class="parallelogram sm:hidden relative shadow-xl sm:rounded-2xl sm:overflow-hidden h-72 sm:mt-8 p-8 mx-0 sm:mx-2">
  <div class="absolute inset-0">
    <ts-picture class="object-cover w-full h-full" [img]="school.imgRoomTeaser" width="100%" height="100%">
    </ts-picture>
    <div class="absolute inset-0 bg-gray-600 mix-blend-multiply"></div>
  </div>

  <div class="relative h-full flex flex-col justify-center items-center">
    <h2 class="text-4xl font-bold text-white">
      {{ school.has_hall_rental ? "Die Säle" : "Die Tanzschule" }}
    </h2>
    <div class="text-sm mt-6 max-w-prose text-white" *ngIf="school.has_hall_rental">
      <p>Sie lieben es, zu feiern und suchen nach einem passenden Ambiente?</p>
      <p>
        Ob Hochzeiten, Geburtstage, Betriebsfeiern oder Empfänge - bei uns ist
        alles möglich!
      </p>
    </div>
    <button class="sm:hidden block rounded-full px-3 py-1.5 bg-orange text-white text-sm font-bold mt-6"
      [routerLink]="['/saele']">
      Mehr erfahren
    </button>
  </div>
</div>
