<div class="w-full h-full rounded-2xl flex" *ngIf="
          (!upcommingCourses || upcommingCourses.length === 0)
        ">
  <div class="flex-1 rounded-2xl overflow-hidden shadow-lg relative">
    <div class="absolute inset-0">
      <!-- <ts-picture [img]="news[0]?.image_name"></ts-picture> -->
    </div>
    <div class="absolute inset-0 bg-translucent flex items-center justify-center">
      <span class="text-white text-5xl font-bold">Herzlich Willkommen</span>
    </div>
  </div>
</div>

<div *ngIf="upcommingCourses && upcommingCourses.length > 0" class="w-full rounded-2xl flex">
  <div class="flex w-full gap-4">
    <div class="flex-1 rounded-2xl overflow-hidden shadow-lg relative" *ngFor="let room of upcommingCourses">
      <div class="absolute inset-0">
        <ts-picture *ngIf="room.room && room.room.img1" [img]="room.room.img1">
        </ts-picture>
      </div>
      <div class="absolute inset-0 bg-translucent"></div>
      <span class="text-white text-lg absolute left-4 bottom-4">{{
        room.room.name
        }}</span>
      <div class="relative z-10 h-full flex items-center">
        <ul class="space-y-12 w-full h-full flex flex-col justify-evenly">
          <li *ngIf="room.currentCourse && room.currentCourse.id" class="flex flex-col items-center">
            <span class="text-zinc-200">
              bis
              {{ room.currentCourse.time_end | date: "HH:mm" }}
              Uhr</span>
            <span class="text-4xl font-bold text-white mt-1">{{
              room.currentCourse.description
              }}</span>
            <span class="text-white text-sm mt-1">{{
              room.currentCourse.courseLevel.ageClass.nameTitle
              }}</span>
          </li>
          <li *ngIf="room.nextCourse && room.nextCourse.id" class="flex flex-col items-center">
            <span class="text-zinc-200">
              ab {{ room.nextCourse?.time | date: "HH:mm" }} Uhr</span>
            <span class="text-4xl font-bold text-white mt-1">{{
              room.nextCourse?.description
              }}</span>
            <span class="text-white text-sm mt-1">{{
              room.nextCourse?.courseLevel?.ageClass.nameTitle
              }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
