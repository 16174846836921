import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendFullSizeDto } from '../../../../shared/src/dtos/backend/backend.full-size.dto';
import { Column } from '../models/column';
import { ConvService } from './conv.service';
import { CrudService } from './crud.service';
import { TsHttpService } from './ts-http.service';

@Injectable({
  providedIn: 'root'
})
export class BackendService extends CrudService<BackendFullSizeDto<any>> {

  static prefix = 'backend';

  _showFullMenu = true;

  constructor(
    public httpSrv: TsHttpService,
    public convSrv: ConvService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    super(httpSrv, BackendService.prefix, BackendFullSizeDto, convSrv);
    if (isPlatformBrowser(this.platformId)) {
      this.showFullMenu = localStorage.getItem('showFullMenu') === 'true';
    }
  }

  get showFullMenu(): boolean {
    return this._showFullMenu;
  }

  set showFullMenu(value: boolean) {
    this._showFullMenu = value;
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('showFullMenu', value ? 'true' : 'false');
    }
  }

  findByName(tableName: string): Observable<BackendFullSizeDto<any>> {
    return this.httpSrv.get<BackendFullSizeDto<any>>(`${BackendService.prefix}/names/${tableName}`);
  }

  findColumnNames(tableName: string): Observable<Column[]> {
    return this.httpSrv.get(`${BackendService.prefix}/names/${tableName}/columns`);
  }
}
