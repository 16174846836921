<div [@routerTransition]="">
  <div class="max-w-6xl mx-auto sm:px-4 flex flex-col">
    <ts-news-header></ts-news-header>
    <ts-course-cards class="flex flex-col divide-y sm:divide-y-0 divide-gray-200 sm:flex-row sm:space-x-4 sm:mt-12">
    </ts-course-cards>
  </div>
  <div *ngIf="courses && courses.length > 0" class="bg-gray-100 py-4 sm:py-12 mt-6 sm:mb-6 shadow-inner">
    <div class="max-w-6xl mx-auto sm:px-4">
      <ts-course-featured class="mt-6 sm:mt-12"></ts-course-featured>
    </div>
  </div>
  <div class="max-w-6xl mx-auto sm:px-4 flex flex-col">
    <ts-event-next></ts-event-next>
    <ts-room-teaser></ts-room-teaser>
  </div>
  <div class="bg-gradient-to-br from-orangeLight to-orange py-8 sm:mb-8 shadow-inner -mt-10 sm:mt-8">
    <div class="max-w-6xl mx-auto px-4 mt-10 sm:mt-0">
      <ts-school-concept></ts-school-concept>
    </div>
  </div>
  <div class="max-w-6xl mx-auto sm:px-4 flex flex-col">
    <ts-team-card></ts-team-card>
    <ts-dance-grid></ts-dance-grid>
    <!--<ts-school-facebook-grid></ts-school-facebook-grid>-->
    <ts-school-contact></ts-school-contact>
  </div>
</div>
