<div *ngIf="weather" class="w-full rounded-2xl shadow-lg bg-orange p-4 flex flex-col">
  <span class="font-bold text-base text-orangeLightX">Wetter</span>
  <div class="mt-2 flex items-center space-x-4">
    <div class="p-2 bg-orangeLight rounded-lg">
      <img class="h-16 w-16" [src]="
                'https://openweathermap.org/img/wn/' +
                weather?.weather[0].icon +
                '@2x.png'
              " alt="" />
    </div>
    <div class="text-bold flex flex-col">
      <span class="text-5xl font-bold text-white">{{ temperature }} °C</span>
      <span class="text-white text-2xl">{{
        weather?.weather[0].description
        }}</span>
    </div>
  </div>
</div>