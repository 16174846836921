import { Component, OnInit } from '@angular/core';
import { School } from '../../models/school';
import { MovieService } from '../../services/movie.service';
import { PartnerService } from '../../services/partner.service';
import { SchoolService } from '../../services/school.service';
import { TeamService } from '../../services/team.service';
import { TimeService } from '../../services/time.service';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ts-school-sidenav-menu',
    templateUrl: './school-sidenav-menu.component.html',
    styleUrls: ['./school-sidenav-menu.component.css'],
    standalone: true,
    imports: [NgIf, RouterLink, MatIcon]
})
export class SchoolSidenavMenuComponent implements OnInit {

  public school: School = new School;
  public hasTeammembers = false;
  public hasPartners = false;
  public hasImageMovies = false;
  public now: Number = TimeService.now.getFullYear();

  constructor(
    private schoolSrv: SchoolService,
    private teamSrv: TeamService,
    private partnerSrv: PartnerService,
    private imageMovieSrv: MovieService,
  ) { }

  ngOnInit() {
    this.schoolSrv.current().subscribe((school) => {
      this.school = school;
      this.teamSrv.findBySchool(this.school.id).subscribe(teammembers => {
        this.hasTeammembers = teammembers.length > 0;
      });
      this.partnerSrv.findBySchool(this.school.id).subscribe(partners => {
        this.hasPartners = partners.length > 0;
      });
      this.imageMovieSrv.findBySchool(this.school.id).subscribe(movies => {
        this.hasImageMovies = movies.length > 0;
      });
    });
  }

}
