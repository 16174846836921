import { DbObj } from './dbObj';

export class CourseStartDate implements DbObj {

    public id: number;
    public date: string;
    public visible_public: boolean;
    public is_full: number;
    public is_full_for_f: number;
    public is_full_for_m: number;
    public checked: boolean;
    private fk_course: number;

    constructor() { }

    get prettyName() {
        return this.date;
    }

    get subtitle() {
        return '';
    }
}
