<div class="relative w-full rounded-2xl shadow-lg bg-orange p-4 flex flex-col">
  <div class="flex justify-between">
    <span class="font-bold text-base text-orangeLightX">HVV</span>
    <span *ngIf="stationName" class="text-white text-base">ab {{ stationName }}</span>
  </div>
  <ul class="mt-2 relative flex flex-col space-y-2">
    <li *ngFor="let departure of departures" class="flex space-x-3 items-center text-white gap-2">
      <div
        class="text-xl text-center shrink-0 w-12 bg-orangeLightX text-orange px-1.5 py-1 rounded-lg justify-self-end">{{
        departure.line.name }}</div>
      <div class="w-72 text-xl font-bold inline-block whitespace-nowrap text-ellipsis">{{
        departure.line.direction
        }}</div>
      <div class="flex-1 text-xl">in {{ departure.timeOffset }} min
        <span class="text-white" *ngIf="departure.delay">(+{{ departure.delay / 60 }})</span>
      </div>
    </li>
  </ul>
</div>
