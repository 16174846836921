import { ConvService } from '../services/conv.service';
import { DbObj } from './dbObj';

export class AgeClass implements DbObj {
    public id: number;
    public name: string;
    public nameTitle: string;
    public nameUrl: string;
    public icon: string;
    public teaser: string;
    public text: string;
    public show_on_frontend: boolean;
    public imgFrontpage: string;
    public imgCoursepage: string | null;

    constructor() { }

    get prettyName() {
        return this.name;
    }

    get subtitle() {
        return ConvService.truncate(this.teaser, 30);
    }

    public getImgPath(size: String) {
        return this.nameUrl ? `assets/img/age_class/${this.nameUrl}_${size}.jpg` : '';
    }

    public hasImage() {
        return this.nameUrl !== 'sonderkurse' && this.nameUrl !== 'ferienprogramm';
    }
}
