import { DbObj } from "./dbObj";
import { School } from "./school";

export class TvSlide implements DbObj {
  public id: number;
  public description: string;
  public bg_img: string;
  public bg_video: string;
  public school: School;

  constructor() { }

  get prettyName() {
    return this.description || this.id.toString();
  }

  get subtitle() {
    return this.id.toString();
  }
}
