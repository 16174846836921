import { Injectable } from '@angular/core';
import { FooterIcon } from '../models/footerIcon';
import { ConvService } from './conv.service';
import { CrudService } from './crud.service';
import { TsHttpService } from './ts-http.service';

@Injectable({
  providedIn: 'root'
})
export class FooterIconService extends CrudService<FooterIcon> {

  static prefix = 'footerIcons';
  dbTable = 'footer_icon';

  constructor(
    public httpSrv: TsHttpService,
    public convSrv: ConvService,
  ) {
    super(httpSrv, FooterIconService.prefix, FooterIcon, convSrv);
  }
}
