import { NgFor, NgIf } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { RouterLink } from "@angular/router";
import { Dance } from "../../models/dance";
import { DanceService } from "../../services/dance.service";
import { SchoolService } from "../../services/school.service";

@Component({
    selector: "ts-dance-grid",
    templateUrl: "./dance-grid.component.html",
    styleUrls: ["./dance-grid.component.css"],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        RouterLink,
    ],
    host: { ngSkipHydration: "true" },
})
export class DanceGridComponent implements OnInit {
  dances: Dance[] = [];
  filteredDances: Dance[] = [];
  isHidden = true;

  constructor(
    private danceSrv: DanceService,
    private schoolSrv: SchoolService
  ) { }

  onHide() {
    this.filteredDances = this.dances.slice(0, 4);
    this.isHidden = true;
  }

  onShow() {
    this.filteredDances = this.dances;
    this.isHidden = false;
  }

  ngOnInit(): void {
    this.schoolSrv.current().subscribe((school) => {
      this.danceSrv.findBySchool(school.id).subscribe((dances) => {
        this.dances = dances
          .filter(
            (dance) =>
              dance.shortText &&
              dance.shortText.length > 50
          )
          .sort(() => 0.5 - Math.random());
        this.onHide();
      });
    });
  }
}
