<div class="flex flex-col">
  <h3 class="text-gray-500 pl-2 text-sm">Kurse</h3>
  <a class="hover:bg-orangeLightX text-gray-700 py-2 pl-2 font-bold flex items-center gap-2 text-sm"
    *ngFor="let ageClass of ageClasses" [routerLink]="['kurse', ageClass.nameUrl]">
    <mat-icon [innerHtml]="ageClass.icon"></mat-icon>
    {{ ageClass.name }}
  </a>
  <h3 class="text-gray-500 pl-2 text-sm mt-2">Organisation</h3>
  <a class="hover:bg-orangeLightX text-gray-700 py-2 pl-2 font-bold flex items-center gap-2 text-sm"
    routerLink="/konditionen">
    <mat-icon>&#xe926;</mat-icon>
    Konditionen
  </a>
  <a class="hover:bg-orangeLightX text-gray-700 py-2 pl-2 font-bold flex items-center gap-2 text-sm"
    routerLink="/kursfreie-zeiten">
    <mat-icon>&#xe033;</mat-icon>
    Kursfreie Zeiten
  </a>
  <a class="hover:bg-orangeLightX text-gray-700 py-2 pl-2 font-bold flex items-center gap-2 text-sm"
    routerLink="/kuendigung">
    <mat-icon>&#xe0eb;</mat-icon>
    Kündigen
  </a>
  <a class="hover:bg-orangeLightX text-gray-700 py-2 pl-2 font-bold flex items-center gap-2 text-sm"
    [href]="assetsRoot + school.flyer" target="_blank">
    <mat-icon>&#xe415;</mat-icon>
    Download Flyer
  </a>
</div>
