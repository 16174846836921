<div *ngIf="courses.length> 0" class="h-full w-full rounded-2xl shadow-lg bg-orange p-4 flex
    flex-col">
  <span class="font-bold text-base text-orangeLightX">angesagte Kurse</span>
  <ul class="grid grid-rows-1 h-full items-center gap-2 mt-2"
    [style]="'grid-template-columns: repeat(' + courses.length + ', minmax(0, 1fr));'">
    <li class="rounded-xl h-full relative flex flex-col bg-orangeLightX shadow-sm px-4 py-4"
      *ngFor="let course of courses">
      <span class="text-orange text-lg font-extrabold">{{course.courseLevel.ageClass.name}}</span>
      <span class="font-bold text-orange" [class]="{
            'text-4xl': showInOverview,
            'text-2xl': !showInOverview
          }">{{ course.description }}</span>
      <div class="flex space-x-4" [class]="{
            'left-4': showInOverview,
            'right-4': !showInOverview
          }">
        <ul class="flex flex-wrap gap-4 items-start font-medium mt-2">
          <li class="text-2xl text-orange" *ngFor="let date of course?.course_start_dates">
            {{ date.date
            }}</li>
        </ul>
      </div>
    </li>
  </ul>
</div>
