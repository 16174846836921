import { Component, OnInit } from "@angular/core";
import { Course } from "../../models/course";
import { routerTransition } from "../../router.animations";
import { CourseService } from "../../services/course.service";
import { SchoolService } from "../../services/school.service";
import { SchoolContactComponent } from "../../school/school-contact/school-contact.component";
import { DanceGridComponent } from "../../dance/dance-grid/dance-grid.component";
import { TeamCardComponent } from "../../team/team-card/team-card.component";
import { SchoolConceptComponent } from "../../school/school-concept/school-concept.component";
import { RoomTeaserComponent } from "../../room/room-teaser/room-teaser.component";
import { EventNextComponent } from "../../event/event-next/event-next.component";
import { CourseFeaturedComponent } from "../../course/course-featured/course-featured.component";
import { NgIf } from "@angular/common";
import { CourseCardsComponent } from "../../course/course-cards/course-cards.component";
import { NewsHeaderComponent } from "../../news/news-header/news-header.component";

@Component({
    selector: "ts-routing-index",
    templateUrl: "./routing-index.component.html",
    animations: [routerTransition()],
    styleUrls: ["./routing-index.component.css"],
    standalone: true,
    imports: [
        NewsHeaderComponent,
        CourseCardsComponent,
        NgIf,
        CourseFeaturedComponent,
        EventNextComponent,
        RoomTeaserComponent,
        SchoolConceptComponent,
        TeamCardComponent,
        DanceGridComponent,
        SchoolContactComponent,
    ],
})
export class RoutingIndexComponent implements OnInit {
  courses: Course[] = [];

  constructor(
    private schoolSrv: SchoolService,
    private courseSrv: CourseService
  ) {}

  ngOnInit() {
    this.schoolSrv.current().subscribe((school) => {
      this.courseSrv.featuredCourses(school.id).subscribe((courses) => {
        this.courses = courses;
      });
    });
  }
}
