import { DbObj } from "./dbObj";

export class School implements DbObj {
  public id: number;
  public short_name: string;
  public name: string;
  public company_name: string;
  public homepage_url: string;
  public facebook_url: string;
  public facebook_page_id: string;
  public google_place_id: string;
  public instagram_url: string;
  public snapchat_url: string;
  public voucher_url: string;
  public street: string;
  public plz: string;
  public city: string;
  public telephone: string;
  public telefax: string;
  public e_mail: string;
  public e_mail_registration: string;
  public e_mail_private_course: string;
  public sepa: string;
  public iban_school: string;
  public maps_link: string;
  public lat: number;
  public lng: number;
  public password_for_videos: string;
  public imprint_infos: string;
  public has_gallery: boolean;
  public has_partners: boolean;
  public show_holiday_courses: boolean;
  public has_hall_rental: boolean;
  public has_linedance_videos: boolean;
  public has_image_movies: boolean;
  public free_time: string;
  public conditions: string;
  public flyer: string;
  public course_program_file: string;
  public imgRoomTeaser: string;
  public imgTeamTeaser: string;
  public currentInfos: string;
  public stationName: string;

  public imgAboutUs: string;
  public aboutUsText: string;
  public drinksText: string;

  private _token: string;
  private _tokenExpirationDate: Date;

  public baseUrl = "assets/img/logos/";
  public socialBaseUrl = "assets/img/";

  private socialSet = false;
  private _socialIcons = [];
  public get socialIcons() {
    if (this.facebook_url && !this.socialSet) {
      this._socialIcons = [
        {
          title: "Besuch uns bei Facebook",
          url: `${this.socialBaseUrl}facebook.svg`,
          alt: "Besuch uns bei Facebook",
          href: this.facebook_url,
        },
        {
          title: "Besuch uns bei Instagram",
          url: `${this.socialBaseUrl}instagram.svg`,
          alt: "Besuch uns bei Instagram",
          href: this.instagram_url,
        },
        {
          title: "Besuch uns bei Snapchat",
          url: `${this.socialBaseUrl}snapchat.svg`,
          alt: "Besuch uns bei Snapchat",
          href: this.snapchat_url,
        },
      ];
      this.socialSet = true;
    }
    return this._socialIcons;
  }

  constructor() {}

  get prettyName() {
    return this.name;
  }

  get subtitle() {
    return this.city;
  }

  get token() {
    if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
      return null;
    }
    return this._token;
  }

  set token(token: string) {
    if (this._token) {
      return;
    }
    this._token = token;
  }

  set tokenExpirationDate(date: Date) {
    if (this._tokenExpirationDate) {
      return;
    }
    this._tokenExpirationDate = date;
  }

  public getLogoPath(): String {
    return this.short_name
      ? "assets/img/logos/logo_" + this.short_name + ".svg"
      : "";
  }

  public getLogoPathBlack(): String {
    return this.short_name
      ? `assets/img/logos/${this.short_name}_black.svg`
      : "";
  }

  public getFaviconPath(size?: number) {
    return this.short_name
      ? "assets/img/logos/" + this.short_name + "_black.svg"
      : "";
  }

  public getRoomImgPath() {
    return this.short_name ? "assets/img/" + this.short_name + ".saal.jpg" : "";
  }

  public getTeamImgPath() {
    return this.short_name ? "assets/img/" + this.short_name + ".team.jpg" : "";
  }
}
