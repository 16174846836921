export enum BackendDataType {
  number = 'number',
  string = 'string',
  html = 'html',
  boolean = 'boolean',
  enum = 'enum',
  video = 'video',
  time = 'time',
  datetime = 'datetime',
  img = 'img',
  file = 'file',
  join = 'join',
  joinMany = 'joinMany',
}