<footer
  class="pt-10 sm:pt-0 px-2 shadow-inner sm:shadow-none bg-orange text-white flex flex-col items-center lg:items-stretch w-full">
  <div
    class="py-2 mb-10 lg:mb-0 flex flex-col lg:flex-row lg:justify-between space-y-4 lg:space-y-0 lg:space-x-1 space-x-0 items-center h-full">
    <div class="flex space-x-1 items-center">
      <button class="flex hover:bg-orangeHover duration-100 rounded-full px-3 py-1.5 text-sm items-center space-x-1"
        [routerLink]="['/impressum']">
        Impressum
      </button>
      <button class="flex hover:bg-orangeHover duration-100 rounded-full px-3 py-1.5 text-sm items-center space-x-1"
        [routerLink]="['/datenschutz']">
        Datenschutz
      </button>
      <button class="flex hover:bg-orangeHover duration-100 rounded-full px-3 py-1.5 text-sm items-center space-x-1"
        [routerLink]="['/agb']">
        AGB
      </button>
    </div>
    <div class="flex space-x-1 items-center">
      <div class="hidden sm:flex items-center w-full justify-between lg:justify-start gap-6">
        <a target="_blank" class="hidden xs:block z-20" [href]="icon.href" *ngFor="let icon of footerIcons">
          <img class="w-14" [src]="icon.iconUrl" [alt]="icon.alt" />
        </a>
      </div>
    </div>
    <div class="flex items-center gap-6">
      <button (click)="openLink(icon.href)" class="w-10" *ngFor="let icon of school.socialIcons">
        <img [src]="icon.url" [alt]="icon.alt" />
      </button>
    </div>
  </div>
</footer>
