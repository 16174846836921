import { Component, OnInit } from '@angular/core';
import { SchoolService } from '../../services/school.service';
import { School } from '../../models/school';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
// import { Map, LngLat } from 'mapbox-gl';

@Component({
    selector: 'ts-school-contact',
    templateUrl: './school-contact.component.html',
    styleUrls: ['./school-contact.component.css'],
    standalone: true,
    imports: [MatIcon, NgIf]
})
export class SchoolContactComponent implements OnInit {

  public school: School = new School();
  // public position: LngLat;
  // map: Map;

  constructor(private schoolSrv: SchoolService) { }

  /*public updateMap($event) {
    this.map = $event;
    this.map.setCenter(this.position);
  }*/

  ngOnInit() {
    this.schoolSrv.current().subscribe((school => {
      Object.assign(this.school, school);
      // this.position = new LngLat(this.school.lng, this.school.lat);
    }));
  }

}
