<div class="bg-gray-200 sm:bg-white shadow-inner sm:shadow-none">
  <div *ngIf="dances && dances.length > 0" class="mt-6 pt-6 mx-2 md:mx-0 ">
    <h2 class="text-xl sm:text-2xl font-bold text-gray-800 text-center sm:text-left">
      Die Tänze unserer Standard- und Lateinkurse
    </h2>
  </div>
  <div *ngIf="dances && dances.length > 0" class="mx-2 md:mx-0 grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 relative">
    <div *ngIf="isHidden" class="absolute inset-0 bg-gradient-to-t from-gray-50 flex items-end justify-center">
      <button
        class="bg-orangeLightX text-orange rounded-full px-8 py-1.5 shadow-md flex flex-col items-center text-sm relative top-4"
        (click)="onShow()">
        <span>mehr anzeigen</span>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"
          stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
      </button>
    </div>
    <div
      class="bg-gradient-to-br h-full shadow-sm from-orangeLight to-orange rounded-2xl flex flex-col justify-between overflow-hidden"
      *ngFor="let dance of filteredDances">
      <div class="p-4">
        <div class="flex justify-between">
          <span class="text-lg font-bold text-white">{{ dance.name }}</span>
        </div>
        <span class="prose text-white text-sm mt-2" [innerHtml]="dance.shortText"></span>
      </div>
      <button class="font-bold text-white text-sm px-2 pt-2 mb-2 border-t border-orangeLight" [routerLink]="[
        'kurse',
        'sonderkurse',
        dance.courseLevels[0].description_url
      ]" *ngIf="dance.courseLevels && dance.courseLevels.length > 0">
        <span *ngIf="dance?.courseLevels[0]?.courses?.length > 0">{{ dance.name }}-Kurse ansehen</span>
        <span *ngIf="!dance?.courseLevels[0]?.courses || dance?.courseLevels[0]?.courses?.length === 0">mehr
          erfahren</span>
      </button>
    </div>
  </div>
  <div class="h-6 sm:hidden bg-gradient-to-b from-gray-200 to-white"></div>
</div>
