
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImageMovie } from '../models/imageMovie';
import { ConvService } from './conv.service';
import { CrudService } from './crud.service';
import { TsHttpService } from './ts-http.service';

@Injectable({
  providedIn: 'root'
})
export class MovieService extends CrudService<ImageMovie> {

  static prefix = 'imageMovies';
  dbTable = 'image_movie';

  constructor(
    public httpSrv: TsHttpService,
    public convSrv: ConvService
  ) {
    super(httpSrv, MovieService.prefix, ImageMovie, convSrv);
  }

  public bySchoolId(schoolId: number): Observable<ImageMovie[]> {
    const request = `schools/${schoolId}/${MovieService.prefix}`;
    return this.httpSrv
      .get<ImageMovie[]>(request).pipe(
        map(movies => this.convSrv
          .convert2TypedArray<ImageMovie>(movies, ImageMovie)));
  }
}
