<div *ngIf="room" class="h-screen w-full flex flex-col bg-white" style="min-height: -webkit-fill-available;">

  <ts-infosystem-toolbar *ngIf="room?.id" [schoolId]="school?.id" [room]="room"></ts-infosystem-toolbar>
  <div class="flex flex-col h-full justify-center">
    <div class="grid h-full grid-cols-3 grid-row-6 m-4 gap-4 max-h-exceptTvMenu">
      <ts-infosystem-upcomming-courses class="col-span-2 row-span-4 flex" *ngIf="school?.id && !room?.showInOverview"
        [schoolId]="school?.id">
      </ts-infosystem-upcomming-courses>

      <ts-infosystem-gallery [schoolId]="school?.id" *ngIf="school?.id && room?.showInOverview"
        class="col-span-2 row-span-4 object-cover"></ts-infosystem-gallery>

      <ts-infosystem-events class="row-span-1 col-span-1 flex items-center" *ngIf="school?.id" [schoolId]="school?.id">
      </ts-infosystem-events>
      <ts-infosystem-hvv class="col-span-1 row-span-2" [schoolId]="school?.id" [stationName]="school?.stationName"
        *ngIf="room?.showInOverview">
      </ts-infosystem-hvv>
      <ts-infosystem-weather class="col-span-1" *ngIf="school?.id && room?.showInOverview" [lat]="school.lat"
        [lng]="school.lng">
      </ts-infosystem-weather>

      <ts-infosystem-gallery [schoolId]="school?.id" *ngIf="school?.id && !room?.showInOverview"
        class="col-span-1 row-span-3 h-full"></ts-infosystem-gallery>

      <ts-infosystem-rss
        class="relative w-full rounded-2xl shadow-lg overflow-hidden flex flex-col row-span-1 col-span-1"
        [schoolId]="school?.id" *ngIf="room?.showInOverview"> </ts-infosystem-rss>

      <ts-infosystem-featured-courses *ngIf="school?.id" [schoolId]="school?.id" [showInOverview]="room?.showInOverview"
        class="row-span-1" [ngClass]="{
          'col-span-2': room?.showInOverview,
          'col-span-3': !room?.showInOverview
          }">
      </ts-infosystem-featured-courses>
    </div>
  </div>
</div>
<div class="h-screen w-full flex flex-col items-center justify-center  bg-orange text-white" *ngIf="!room || !room?.id">
  <h1 class="text-3xl font-bold">Bitte wähle einen Raum aus:</h1>
  <div class="mt-10">
    <table class="prose prose-sm text-white">
      <thead>
        <th class="text-white">ID</th>
        <th class="text-white">Tanzschule</th>
        <th class="text-white">Bezeichnung</th>
        <th class="text-white"></th>
      </thead>
      <tbody>
        <tr *ngFor="let roomItem of rooms">
          <td>{{roomItem.id}}</td>
          <td>{{roomItem.school.name}}</td>
          <td>{{roomItem.name}}</td>
          <td><a class="bg-white text-orange px-3 py-1.5 rounded-full shadow-md decoration-transparent"
              [href]="'/?roomId=' + roomItem.id">anzeigen</a></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
