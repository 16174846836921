import { isPlatformBrowser, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { mergeMap, timer } from 'rxjs';

const weatherApiKey = "0cbc748260d01d9053c19247e8bd5f7e";

@Component({
    selector: 'ts-infosystem-weather',
    templateUrl: './infosystem-weather.component.html',
    styleUrls: ['./infosystem-weather.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class InfosystemWeatherComponent implements OnInit {

  @Input() lat: number;
  @Input() lng: number;
  weather: any = null;
  temperature = 0;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient,
  ) { }

  fetchWeather() {
    if (isPlatformBrowser(this.platformId)) {
      timer(0, 60000)
        .pipe(
          mergeMap(() =>
            this.http.get(
              `https://api.openweathermap.org/data/2.5/weather?lat=${this.lat}&lon=${this.lng}&appid=${weatherApiKey}&units=metric&lang=de`
            )
          )
        )
        .subscribe((weatherData) => {
          this.weather = weatherData;
          this.temperature = Math.round(this.weather.main.temp);
        });
    }
  }

  ngOnInit(): void {
    this.fetchWeather();
  }

}
