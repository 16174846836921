import { isPlatformBrowser, NgIf, NgFor, DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import * as dayjs from 'dayjs';
import { interval } from 'rxjs';
import { Room } from '../../models/room';
import { CourseService } from '../../services/course.service';
import { RoomService } from '../../services/room.service';

@Component({
    selector: 'ts-infosystem-toolbar',
    templateUrl: './infosystem-toolbar.component.html',
    styleUrls: ['./infosystem-toolbar.component.css'],
    standalone: true,
    imports: [NgIf, NgFor, DatePipe]
})
export class InfosystemToolbarComponent implements OnInit {

  @Input() room: Room;
  @Input() schoolId: number;
  rooms: Room[] = [];
  time = new Date();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private courseSrv: CourseService,
    private roomSrv: RoomService,
  ) { }

  updateCoursesForSingleRoom(room: Room): Promise<Room> {
    return new Promise((resolve) => {
      this.courseSrv.currentCourse(room.id).subscribe((course) => {
        room.currentCourse = course;

        this.courseSrv.nextCourse(room.id).subscribe((course) => {
          room.nextCourse = course;
          const now = dayjs();
          const currentTime = dayjs("1970-01-01T00:00:00+01:00")
            .add(now.hour(), "hour")
            .add(now.minute(), "minute");
          room.timeUntilNextCourse = dayjs(room.nextCourse.time).from(currentTime);
          resolve(room);
        });
      });
    });
  }

  async updateCoursesForAllRooms() {
    this.roomSrv.findBySchool(this.room.fk_school).subscribe(async (rooms) => {
      this.rooms = (await Promise.all(rooms.map(room => {
        return this.updateCoursesForSingleRoom(room);
      }))).filter(room => room.currentCourse?.id || room.nextCourse?.id);
    });
  }

  updateTime() {
    if (isPlatformBrowser(this.platformId)) {
      interval(1000).subscribe(() => {
        this.time = new Date();
      });
    }
  }

  async update() {
    if (!this.room.showAllCoursesInToolbar) {
      this.room = await this.updateCoursesForSingleRoom(this.room);
    } else {
      await this.updateCoursesForAllRooms();
    }
  }

  async ngOnInit(): Promise<void> {

    if (isPlatformBrowser(this.platformId)) {
      interval(60000).subscribe(() => {
        this.update();
      });
      this.update();
    }

    this.updateTime();
  }

}
