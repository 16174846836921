
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { News } from '../models/news';
import { ConvService } from './conv.service';
import { CrudService } from './crud.service';
import { TimeService } from './time.service';
import { TsHttpService } from './ts-http.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService extends CrudService<News> {

  static prefix = 'news';
  dbTable = 'news';

  constructor(
    public httpSrv: TsHttpService,
    public convServ: ConvService
  ) {
    super(httpSrv, NewsService.prefix, News, convServ);
  }

  public bySchoolId(schoolId: number): Observable<News[]> {
    return this.httpSrv
      .get<News[]>(`schools/${schoolId}/${NewsService.prefix}`).pipe(
        map(news => this.convServ.convert2TypedArray<News>(news, News)),
        map(news => news.filter(theNews => {
          return new Date(theNews.publish_start) < TimeService.now
            && new Date(theNews.publish_end) > TimeService.now;
        }))
      );
  }

  public byUrlTitle(urlTitle: string): Observable<News> {
    return this.httpSrv
      .get(`${NewsService.prefix}/urlTitle/${urlTitle}`).pipe(
        map(news => Object.assign(new News(), news)));
  }
}
