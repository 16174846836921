import { isPlatformBrowser, NgIf, DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { take } from 'rxjs';
import { SchoolService } from '../../services/school.service';
import { TvSlideService } from '../../services/tv-slide.service';

@Component({
    selector: 'ts-infosystem-rss',
    templateUrl: './infosystem-rss.component.html',
    styleUrls: ['./infosystem-rss.component.css'],
    standalone: true,
    imports: [NgIf, DatePipe]
})
export class InfosystemRssComponent implements OnInit {

  @Input() schoolId: number;
  rss: {
    date: string;
    rights: string;
    title: string;
    "mp:image": {
      "mp:data": string;
    };
  }[];
  rssIndex = 0;

  constructor(
    private schoolSrv: SchoolService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  updateRss() {
    this.schoolSrv.getRss(this.schoolId).pipe(take(1)).subscribe((rss) => {
      this.rss = rss;
    });
  }

  getRssImgUrl(index: number) {
    return this.rss && this.rss[index]
      ? this.rss[index]["mp:image"]["mp:data"]
      : "";
  }

  ngOnInit() {
    this.updateRss();

    if (isPlatformBrowser(this.platformId)) {
      setInterval(() => {
        this.rssIndex = this.rssIndex < this.rss.length - 1 ? this.rssIndex + 1 : 0;
      }, TvSlideService.slideDuration);
    }
  }

}
