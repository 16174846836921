import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as dayjs from "dayjs";
import "dayjs/locale/de";
import * as relativeTime from "dayjs/plugin/relativeTime";
import { Room } from "../../models/room";
import { School } from "../../models/school";
import { RoomService } from "../../services/room.service";
import { InfosystemFeaturedCoursesComponent } from "../infosystem-featured-courses/infosystem-featured-courses.component";
import { InfosystemRssComponent } from "../infosystem-rss/infosystem-rss.component";
import { InfosystemWeatherComponent } from "../infosystem-weather/infosystem-weather.component";
import { InfosystemHvvComponent } from "../infosystem-hvv/infosystem-hvv.component";
import { InfosystemEventsComponent } from "../infosystem-events/infosystem-events.component";
import { InfosystemGalleryComponent } from "../infosystem-gallery/infosystem-gallery.component";
import { InfosystemUpcommingCoursesComponent } from "../infosystem-upcomming-courses/infosystem-upcomming-courses.component";
import { InfosystemToolbarComponent } from "../infosystem-toolbar/infosystem-toolbar.component";
import { NgIf, NgClass, NgFor } from "@angular/common";
dayjs.locale("de");

dayjs.extend(relativeTime);

export const SLIDE_DURATION = 15;

@Component({
    selector: "ts-infosystem-index",
    templateUrl: "./infosystem-index.component.html",
    styleUrls: ["./infosystem-index.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        InfosystemToolbarComponent,
        InfosystemUpcommingCoursesComponent,
        InfosystemGalleryComponent,
        InfosystemEventsComponent,
        InfosystemHvvComponent,
        InfosystemWeatherComponent,
        InfosystemRssComponent,
        InfosystemFeaturedCoursesComponent,
        NgClass,
        NgFor,
    ],
})
export class InfosystemIndexComponent implements OnInit {
  school: School = new School();

  room: Room | null = null;
  rooms: Room[] = [];

  constructor(
    private route: ActivatedRoute,
    private roomSrv: RoomService,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const roomId = params["roomId"];

      if (roomId) {
        this.roomSrv.findById(roomId).subscribe((room: Room) => {
          this.room = room;
          this.school = room.school;
        });
      } else {
        this.roomSrv.findAll().subscribe(rooms => {
          this.rooms = rooms;
        })
      }
    });
  }
}
