<div class="py-4 sm:p-4 rounded-larger shadow-none flex flex-col justify-start" *ngIf="courses.length > 0">
  <div>
    <h2 class="text-3xl font-bold text-left pl-4 sm:pl-2 text-gray-800">Angesagte Kurse</h2>
  </div>
  <div class="sm:mt-4 flex overflow-x-auto overflow-y-hidden whitespace-nowrap space-x-4 pb-2">
    <div *ngFor="let course of courses" class="p-4 sm:p-2 flex flex-col">
      <div class="flex flex-col">
        <div class="text-sm text-gray-600">
          {{ course.courseLevel.ageClass.name }}
        </div>
        <div class="flex justify-between items-center space-x-2">
          <h3 class="font-bold text-xl text-gray-700">
            {{ course.description }}
          </h3>
          <button class="text-secondary opacity-60 relative top-1" *ngIf="
              course?.courseLevel.long_text &&
              course?.courseLevel.long_text.length > 0
            " [routerLink]="[
              '/kurse',
              course?.courseLevel.ageClass.nameUrl,
              course?.courseLevel.description_url
            ]">
            <mat-icon>&#xe88e;</mat-icon>
          </button>
        </div>
        <div class="text-sm text-gray-600">
          {{ course.weekday }}, {{ course.getTimeText() }}
        </div>
      </div>
      <div class="mt-1 gap-1 flex flex-wrap max-w-sm">
        <div *ngFor="let startDate of course.course_start_dates" [matTooltipDisabled]="
            !(startDate?.is_full_for_f && startDate?.is_full_for_m)

          " matTooltipPosition="above" matTooltip="Der Termin ist leider ausgebucht.">
          <button class="bg-orangeLight px-3 py-1.5 shadow-md text-white rounded-full text-xs" [disabled]="
              startDate.is_full_for_m === 1 && startDate.is_full_for_f === 1
            " [routerLink]="['/buchen', course.id, startDate.id]">
            {{ startDate?.date }}
          </button>
        </div>
        <button disabled class="bg-gray-200 cursor-default px-3 py-1.5 shadow-md rounded-full text-xs"
          *ngIf="course.course_start_dates.length === 0" [routerLink]="['/buchen', course.id]">
          {{
          course.noStartDatesText
          ? course.noStartDatesText
          : course.courseLevel.no_start_dates_text
          }}
        </button>
      </div>
    </div>
  </div>
</div>
