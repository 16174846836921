import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BusinessPartner } from '../models/businessPartner';
import { ConvService } from './conv.service';
import { CrudService } from './crud.service';
import { TsHttpService } from './ts-http.service';

@Injectable({
  providedIn: 'root'
})
export class PartnerService extends CrudService<BusinessPartner> {

  static prefix = 'businessPartners';
  dbTable = 'business_partner';

  constructor(
    public httpSrv: TsHttpService,
    public convSrv: ConvService,
  ) {
    super(httpSrv, PartnerService.prefix, BusinessPartner, convSrv);
  }

  public bySchoolId(schoolId: number) {
    const request = `schools/${schoolId}/${PartnerService.prefix}`;
    return this.httpSrv
      .get<BusinessPartner[]>(request).pipe(
        map(partners => this.convSrv
          .convert2TypedArray<BusinessPartner>(partners, BusinessPartner)));
  }
}
