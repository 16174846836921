import "reflect-metadata";

import { registerLocaleData } from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import localeDe from '@angular/common/locales/de';
import { bootstrapApplication } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { AppComponent } from "./app/app.component";
import { appConfig } from "./app/app.config";


registerLocaleData(localeDe, 'de');

// test
document.addEventListener("DOMContentLoaded", () => {
  bootstrapApplication(AppComponent, {
    providers: [
      provideAnimations(),
      provideHttpClient(withInterceptorsFromDi()),
      ...appConfig.providers,
    ],
  }).catch((err) => console.log(err));
});
