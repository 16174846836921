import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Course } from "../models/course";
import { Room } from "../models/room";
import { School } from "../models/school";
import { ConvService } from "./conv.service";
import { CrudService } from "./crud.service";
import { TsHttpService } from "./ts-http.service";

@Injectable({
  providedIn: "root",
})
export class RoomService extends CrudService<Room> {
  static prefix = "rooms";
  dbTable = "room";

  constructor(public httpSrv: TsHttpService, public convServ: ConvService) {
    super(httpSrv, RoomService.prefix, Room, convServ);
  }

  public findById(id: number): Observable<Room> {
    return this.httpSrv.get<Room>(`rooms/${id}`).pipe(
      map((room) => {
        room.school = Object.assign(new School(), room.school);
        room.courses = this.convServ.convert2TypedArray<Course>(
          room.courses,
          Course
        );
        return room;
      })
    );
  }

  public bySchoolId(schoolId: number): Observable<Room[]> {
    return this.httpSrv
      .get<Room[]>(`schools/${schoolId}/${RoomService.prefix}`)
      .pipe(map((news) => this.convServ.convert2TypedArray<Room>(news, Room)));
  }
}
