import { Component, OnInit } from '@angular/core';
import { SchoolService } from '../../services/school.service';
import { School } from '../../models/school';
import { NgFor } from '@angular/common';

@Component({
    selector: 'ts-school-links-toolbar',
    templateUrl: './school-links-toolbar.component.html',
    styleUrls: ['./school-links-toolbar.component.css'],
    standalone: true,
    imports: [NgFor]
})
export class SchoolLinksToolbarComponent implements OnInit {

  public schools: School[] = new Array();

  constructor(private schoolSrv: SchoolService) { }

  ngOnInit() {
    this.schoolSrv.others().subscribe((schools: School[]) => {
        this.schools = schools;
    });
  }

}
